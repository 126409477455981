import React from "react";
import { withPartnerConsumer } from "../../partner-context";
import Partner from "./PartnerFeatures";

function PartnerContainer({ context }) {
    const { partners } = context;

    return (
        <>
            {partners.map((item, index) => {
                return <Partner key={index} partner={item} />;
            })}
        </>
    );
}
export default withPartnerConsumer(PartnerContainer);
