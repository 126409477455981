import React, { useState } from 'react';
import SectionsTitle from '../common/SectionsTitle';
import { FiChevronRight } from 'react-icons/fi';
import ContactSidebar from './ContactSidebar';
import Maps from './Maps';
import SocialProfile from "../widgets/SocialProfile";
import sectiondata from "../../store/store";
import { useLocation } from 'react-router-dom';

export default function ContactDetails() {
    const location = useLocation();
    const lastSegment = location.pathname.split('/').pop();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = `${process.env.REACT_APP_DERRICO_API}/crm/`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            console.log(result); // Handle your success state here
            alert('Message sent successfully!');
        } catch (error) {
            console.error('There was an issue with sending the form:', error);
            alert('Failed to send message.');
        }
    };

    return (
        <section className="contact-form-wrapper">
            <div className="container">
                {lastSegment === 'contato' && (
                    <div className="contact-form-grid d-grid">
                        <div className="left">
                            <SectionsTitle title={sectiondata.contactdata.secTitle}
                                           subtitle={sectiondata.contactdata.secSubtitle}/>
                            <p>{sectiondata.contactdata.content}</p>
                            <div className="sidebar-social-profile">
                                <SocialProfile lists={sectiondata.contactdata.socials}/>
                            </div>
                        </div>
                        <div className="right">
                            <form onSubmit={handleSubmit}>
                                <div className="form-grid d-grid">
                                    <div className="left">
                                        <label htmlFor="name">Seu Nome <sup>*</sup></label>
                                        <input type="text" id="name" name="name" placeholder="Nome Completo" required
                                               value={formData.name} onChange={handleChange}/>
                                    </div>
                                    <div className="right">
                                        <label htmlFor="email">Seu E-mail <sup>*</sup></label>
                                        <input type="email" id="email" name="email" placeholder="Endereço de E-mail"
                                               required value={formData.email} onChange={handleChange}/>
                                    </div>
                                </div>
                                <div className="right">
                                    <label htmlFor="subject">Assunto <sup>*</sup></label>
                                    <input type="text" id="subject" name="subject" placeholder="Assunto" required
                                           value={formData.subject} onChange={handleChange}/>
                                </div>
                                <div className="form-textarea">
                                    <label htmlFor="message">Sua Mensagem <sup>*</sup></label>
                                    <textarea id="message" name="message" placeholder="Escreva sua Mensagem"
                                              value={formData.message} onChange={handleChange}></textarea>
                                </div>
                                <button type="submit" className="theme-button">Nos Envie Sua Mensagem <FiChevronRight
                                    className="icon"/></button>
                            </form>
                        </div>
                    </div>
                )}

                <div className="contact-form-map-grid d-grid">
                    <div className="left">
                        <Maps/>
                    </div>
                    <div className="right">
                        <ContactSidebar/>
                    </div>
                </div>
            </div>
        </section>
    );
}
