import React  from 'react'
import PortfolioDetailsContent from '../components/portfolio/PortfolioDetailsContent'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import CtaForm from '../components/other/CtaForm'
import Divider from '../components/other/Divider'
import Footer from '../components/other/Footer'
import PortfolioAssessoriaDetailsContent from "../components/portfolio/PortfolioAssessoriaDetailsContent";
import PortfolioSoftwareHouseDetailsContent from "../components/portfolio/PortfolioSoftwareHouseDetailsContent";
import PortfolioObservabilityDetailsContent from "../components/portfolio/PortfolioObservabilityDetailsContent";

export default function PortfolioObservabilityDetailsPage() {
    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Observability" />

            {/* Portfolio Details */}
            <PortfolioObservabilityDetailsContent />

            {/* Footer */}
            <Footer />
        </>
    )
}
