import React  from 'react'
import Navbar from "../components/common/Navbar";
import SectionsTitle from '../components/common/SectionsTitle'
import About1 from '../components/about/About1'
import Divider from '../components/other/Divider'
import About from '../components/about/About'
import CounterUp from "../sections/CounterUp";
import HeroSlider from '../components/sliders/HeroSlider'
import SimpleTab from '../components/tabs/SimpleTab'
import Cta from "../sections/Cta";
import Team from '../components/team/Team';
import ChartArea from "../sections/ChartArea";
import Clients from "../sections/Clients";
import Pricing from "../sections/Pricing";
import CtaForm from '../components/other/CtaForm';
import Blog from '../components/blog/Blog';
import { Link } from 'react-router-dom'
import { FiChevronRight } from "react-icons/fi";
import Footer from '../components/other/Footer';
import sectiondata from "../store/store";
import ServiceSection1 from "../sections/services/ServiceSection1";
import TestimonialSection from "../sections/TestimonialSections";
import {FaWhatsapp} from "react-icons/fa";
import {FaWindowClose} from "react-icons/fa";
import About2 from "../components/about/About2";
import ContactDetails from "../components/contact/ContactDetails";
import ContactSection from "../components/contact/ContactSection";
import ServiceSliderSection from "../sections/ServiceSliderSection";

export default function Home() {

    const navigatorLanguage = navigator.language;

    switch (navigatorLanguage) {
        case 'pt-BR':
            localStorage.setItem('language', 'pt');
            break;
        case 'en-US':
            localStorage.setItem('language', 'en');
            break;
        default:
            localStorage.setItem('language', 'pt');
    }

    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar/>
            </header>

            {/* Hero Slider */}
            <HeroSlider slideItems={sectiondata.Sliders.heroSlider1}/>

            {/*<Divider />*/}

            {/* About 1*/}
            {/*<About1/>*/}
            {/*<Divider />*/}

            {/* About 2 */}
            <About/>

            {/* About 2 */}
            <About2
                lfTitle={sectiondata.Aboutdata.about2.left.title}
                lfContent1={sectiondata.Aboutdata.about2.left.content1}
                lfContent2={sectiondata.Aboutdata.about2.left.content2}
                lfLists={sectiondata.Aboutdata.about2.left.lists}
                lfButtontext={sectiondata.Aboutdata.about2.left.buttontext}
                lfButtonurl={sectiondata.Aboutdata.about2.left.buttonurl}
                rtImg1={sectiondata.Aboutdata.about2.right.img1}
                ftImg2={sectiondata.Aboutdata.about2.right.img2}
                rtTitle={sectiondata.Aboutdata.about2.right.title}
                rtContent={sectiondata.Aboutdata.about2.right.content}
                rtButtontext={sectiondata.Aboutdata.about2.right.buttontext}
                rtButtonurl={sectiondata.Aboutdata.about2.right.buttonurl}
            />

            {/* Services */}
            <ServiceSection1
                secTitle={sectiondata.Services.ComoFazemos.secTitle}
                secSubtitle={sectiondata.Services.ComoFazemos.secSubtitle}
                secButton={sectiondata.Services.ComoFazemos.secButton}
                secButtonUrl={sectiondata.Services.ComoFazemos.secButtonUrl}
                serviceItems={sectiondata.Services.ComoFazemos.serviceLists}
            />

            {/* Services Slider*/}
            <section className="service-slider-wrapper">
                <div className="container">
                    <div className="left">
                        <SectionsTitle title="Nossos Produtos" subtitle=""/>
                    </div>
                    <ServiceSliderSection
                        serviceSlideritem={sectiondata.Services.NossosProdutos.lists}
                        sectitle={sectiondata.Services.NossosProdutos.sectitle}
                        secsubtitle={sectiondata.Services.NossosProdutos.secsubtitle}
                        content={sectiondata.Services.NossosProdutos.content}
                        buttontext={sectiondata.Services.NossosProdutos.buttontext}
                        buttonurl={sectiondata.Services.NossosProdutos.buttonurl}/>
                </div>
            </section>

            <section className="contact-form-wrapper">
                <div className="container">
                    <div className="left">
                        <SectionsTitle title="Clientes e Parceiros"/>
                        <p>Na DERRICO temos orgulho de colaborar com uma variedade de clientes e parceiros que confiam
                            em nossa
                            expertise para impulsionar operações.</p>
                    </div>
                    <Clients sliders={sectiondata.Sliders.clientlogos}/>
                </div>
            </section>

            {/* Counter Up */}
            <CounterUp
                secTitle={sectiondata.CounterUp.secTitle}
                secSubtitle={sectiondata.CounterUp.secSubtitle}
                counteritems={sectiondata.CounterUp.items}
                counterbottomtitle={sectiondata.CounterUp.counterbottomtitle}
                counterbottombtn={sectiondata.CounterUp.counterbottombtn}
                counterbottombtnurl={sectiondata.CounterUp.counterbottombtnurl}/>

            {/* Why Choose Us */}
            {/*<SimpleTab secTitle={sectiondata.Tabdata.tab1.secTitle} secSubtitle={sectiondata.Tabdata.tab1.secSubtitle}*/}
            {/*           tabslist={sectiondata.Tabdata.tab1.items} tabsnav={sectiondata.Tabdata.tab1.tabsnav}/>*/}

            {/* Counter Up */}
            {/*<CounterUp*/}
            {/*    secTitle={sectiondata.CounterUp.secTitle}*/}
            {/*    secSubtitle={sectiondata.CounterUp.secSubtitle}*/}
            {/*    counteritems={sectiondata.CounterUp.items}*/}
            {/*    counterbottomtitle={sectiondata.CounterUp.counterbottomtitle}*/}
            {/*    counterbottombtn={sectiondata.CounterUp.counterbottombtn}*/}
            {/*    counterbottombtnurl={sectiondata.CounterUp.counterbottombtnurl} />*/}

            {/* Testimonial*/}
            {/*<TestimonialSection*/}
            {/*    secTitle={sectiondata.Testimonialdata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Testimonialdata.secSubtitle}*/}
            {/*    sliders={sectiondata.Sliders.testimonial}*/}
            {/*    imgshapes={sectiondata.Testimonialdata.imagesshape} />*/}

            {/* CTA */}
            {/*<Cta />*/}

            {/* Team */}
            {/*<Team*/}
            {/*    secTitle={sectiondata.Teammemberdata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Teammemberdata.secSubtitle}*/}
            {/*    teammembers={sectiondata.Teammemberdata.teammembers} />*/}
            {/*<Divider />*/}

            {/* ChartArea */}
            {/*<ChartArea*/}
            {/*    secTitle={sectiondata.Chartareadata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Chartareadata.secSubtitle}*/}
            {/*    title1={sectiondata.Chartareadata.title1}*/}
            {/*    title2={sectiondata.Chartareadata.title2}*/}
            {/*    content1={sectiondata.Chartareadata.content1}*/}
            {/*    content2={sectiondata.Chartareadata.content2}*/}
            {/*    buttontext={sectiondata.Chartareadata.buttontext}*/}
            {/*    buttonurl={sectiondata.Chartareadata.buttonurl} />*/}

            {/* Clients Logo */}
            {/*<Clients sliders={sectiondata.Sliders.clientlogos} />*/}

            {/* Pricing Table */}
            {/*<Pricing*/}
            {/*    secTitle={sectiondata.Pricingdata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Pricingdata.secSubtitle}*/}
            {/*    navlist={sectiondata.Pricingdata.tablist}*/}
            {/*    planlists={sectiondata.Pricingdata.planlists} />*/}

            {/* Cta Form */}
            {/*<CtaForm/>*/}
            {/*<Divider />*/}

            <ContactSection/>

            {/* Latest News */}
            {/*<div className="h-blog-wrap">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-8">*/}
            {/*                <SectionsTitle title="Our Recent News." subtitle="Latest From The Blog" />*/}
            {/*            </div>*/}
            {/*            <div className="col-4 text-right">*/}
            {/*                <Link to="/blog-grid" className="theme-button">View All Posts <FiChevronRight className="icon" /></Link>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <Blog />*/}
            {/*</div>*/}

            {/* Footer */}
            <Footer/>
        </>
    )
}

