import React  from 'react'
import Navbar from "../components/common/Navbar";
import Breadcrumb from '../components/common/Breadcrumb';
import About from '../components/about/About';
import ServiceSection2 from "../sections/services/ServiceSection2";
import SimpleTab from '../components/tabs/SimpleTab';
import CounterUp from "../sections/CounterUp";
import Team from '../components/team/Team';
import Clients from "../sections/Clients";
import CtaForm from '../components/other/CtaForm';
import Footer from '../components/other/Footer';
import sectiondata from "../store/store";
import DigitalSolutionContent from "../components/digital-solution/DigitalSolutionContent";
import {Link} from "react-router-dom";
import {FaCaretRight} from "react-icons/fa";
import {FiCheckCircle, FiChevronRight} from "react-icons/fi";
import TeamDetails from "./TeamDetails";
import TeamDetailsContent from "../components/team/TeamDetailsContent";
import QuemSomosImg2 from '../assets/images/quem-somos-imagem-2.png';

export default function About_Page() {
    return (
        <main className="about-page">
            {/* Header */}
            <header className="header-area">
                <Navbar/>
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Quem somos"/>

            {/* Services */}
            <ServiceSection2 servicelist={sectiondata.Services.MissaoVisaoValor}/>

            {/* About 2 */}
            <section className="about-st1-wrapper">
                <div className="container">
                    <div className="history-area">
                        <div className="row">
                            <div className="col-8">
                                <img className="history_img" src={QuemSomosImg2} alt="History"/>
                            </div>
                            <div className="history-details-box">
                                {/*<h1 className="history-details-title">*/}
                                {/*    More Than <strong>300 Projects </strong>*/}
                                {/*    Were Completed.*/}
                                {/*</h1>*/}
                                <p className="history-details-desc">
                                    A <b>DERRICO</b> é uma empresa especializada em soluções para a área de tecnologia
                                    da informação e inteligência artificial com foco na melhor experiência do
                                    usuário, redução de custos e aumento dos lucros da empresa, comprometida em
                                    transformar desafios em oportunidades. <b>Fundada em março de 2021</b>,
                                    a DERRICO nasceu com a missão de entregar excelência e inovação para os mais diversos
                                    segmentos.<br/><br/>

                                    Valorizamos <b>a ética, bom atendimento, a transparência, a organização e a
                                    seriedade</b> como pilares fundamentais do nosso trabalho. Buscamos constantemente
                                    parcerias estratégicas que não apenas agregam valor ao nosso negócio, mas também
                                    elevam os resultados dos nossos clientes. Essa visão colaborativa e ética é o que
                                    nos diferencia no mercado.<br/><br/>

                                    <strong>Juntos, vamos construir o futuro da tecnologia!</strong>
                                </p>
                                {/*<Link to="/#" className="theme-button">*/}
                                {/*    Get Started <FiChevronRight className="icon"/>*/}
                                {/*</Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <TeamDetailsContent/>

            {/* Why Choose Us */}
            {/*<SimpleTab secTitle={sectiondata.Tabdata.tab1.secTitle} secSubtitle={sectiondata.Tabdata.tab1.secSubtitle}*/}
            {/*           tabslist={sectiondata.Tabdata.tab1.items} tabsnav={sectiondata.Tabdata.tab1.tabsnav}/>*/}

            {/* Counter Up */}
            {/*<CounterUp*/}
            {/*    secTitle={sectiondata.CounterUp.secTitle}*/}
            {/*    secSubtitle={sectiondata.CounterUp.secSubtitle}*/}
            {/*    counteritems={sectiondata.CounterUp.items}*/}
            {/*    counterbottomtitle={sectiondata.CounterUp.counterbottomtitle}*/}
            {/*    counterbottombtn={sectiondata.CounterUp.counterbottombtn}*/}
            {/*    counterbottombtnurl={sectiondata.CounterUp.counterbottombtnurl} />*/}

            {/*/!* Team *!/*/}
            {/*<Team*/}
            {/*    secTitle={sectiondata.Teammemberdata.secTitle}*/}
            {/*    secSubtitle={sectiondata.Teammemberdata.secSubtitle}*/}
            {/*    teammembers={sectiondata.Teammemberdata.teammembers} />*/}

            {/* Clients Logo */}
            {/*<Clients sliders={sectiondata.Sliders.clientlogos} />*/}
            {/*<div className="form-white-bg">*/}
            {/*    <CtaForm/>*/}
            {/*</div>*/}

            {/* Footer */}
            <Footer/>
        </main>
    )
}
