/* eslint-disable */

import {
    FaApple,
    FaCode,
    FaCoffee, FaDatabase, FaDiscord,
    FaDonate,
    FaFacebookF, FaGit, FaGitlab, FaGitter,
    FaHandshake,
    FaInstagram,
    FaLinkedinIn, FaMobileAlt, FaProjectDiagram, FaTools,
    FaYoutube
} from "react-icons/fa";
import {
    FiBarChart,
    FiBarChart2,
    FiBriefcase,
    FiCheckCircle,
    FiLock,
    FiMonitor,
    FiTarget,
    FiUserCheck,
    FiCode,
    FiCloud,
    FiShoppingBag,
    FiClipboard, FiShoppingCart, FiServer, FiUsers, FiBookOpen, FiGlobe, FiDatabase
} from "react-icons/fi";
import {
    DiReact,
    DiDotnet,
    DiJava,
    DiPython,
    DiPhp,
    DiJavascript,
    DiLaravel,
    DiDjango,
    DiCode,
    DiLinux,
    DiDigitalOcean,
    DiMysql,
    DiMongodb,
    DiJira
} from "react-icons/di";
import {
    SiDocker,
    SiMicrosoftazure,
    SiKubernetes,
    SiPypi,
    SiWebstorm,
    SiPhp,
    SiJetbrains,
    SiPython,
    SiIntellijidea,
    SiVisualstudio,
    SiVisualstudiocode,
    SiXcode,
    SiAndroidstudio,
    SiPostman,
    SiMicrosoftsqlserver,
    SiRancher,
    SiIos,
    SiMicrosoft,
    SiAmazon,
    SiPostgresql,
    SiElasticsearch,
    SiGooglehangouts,
    SiMicrosoftteams,
    SiMicrosoftsharepoint,
    SiClockify, SiTypescript, SiFlutter, SiPowerapps, SiX, SiXamarin, SiWebflow, SiZapier, SiMake
} from "react-icons/si";
import {BiGlobe, BiTestTube, BiWindows} from "react-icons/bi";

import React from "react";

import logo from "../assets/images/logo.png";
import flat1 from '../assets/images/flag.jpg'; // 33x33
import flat2 from '../assets/images/flag2.jpg'; // 33x33
import flat3 from '../assets/images/flag3.jpg'; // 33x33
import herosliderbg from '../assets/images/shop-slider1.jpg'; // 1920x1024
import conceptslider from '../assets/images/digital-blue-hud-interface-laptop-concept.jpg'; // 1920x1024
import strategyconcept from '../assets/images/analytics-plan-strategy-insight-concept.jpg'; // 1920x1024
import condingman from '../assets/images/coding-man.jpg'; // 1920x1024

import {MdAccountBalance} from "react-icons/md";
import {
    AiOutlineCheckCircle,
    AiOutlineDollar,
    AiOutlineFileSearch, AiOutlineMessage,
    AiOutlineNotification,
    AiOutlineRocket,
    AiOutlineUsergroupAdd
} from "react-icons/ai";
import {IoIosBuild, IoIosHammer, IoIosNotificationsOutline, IoLogoPython} from "react-icons/io";
import {TiLightbulb} from "react-icons/ti"; // 750x470
import {TbRocket} from "react-icons/tb";
import {TbClick} from "react-icons/tb";
import {TbTargetArrow} from "react-icons/tb";
import edificioVarig from '../assets/images/EdificioVarig.jpg';
import LibertasFrente from '../assets/images/LibertasFrente.jpeg';
import LibertasLateral from '../assets/images/LibertasLateral.jpeg';
import reuniao1 from '../assets/images/Reuniao-01.jpeg';
import reuniao2 from '../assets/images/Reuniao-02.jpeg';
import img1 from '../assets/images/about-img.jpg'; // 458x315
import img2 from '../assets/images/about-img4.jpg'; // 555x354
import img3 from "../assets/images/testi-img1.jpg"; // 100x100
import img4 from '../assets/images/team-1.jpg'; // 360x370
import img5 from "../assets/images/client1.png"; // 110x39
import img6 from "../assets/images/client2.png"; // 160x32
import img7 from "../assets/images/client3.png"; // 156x33
import img8 from "../assets/images/client4.png"; // 137x36
import img9 from "../assets/images/client5.png"; // 161x35
import img10 from "../assets/images/img1.jpg"; // 555x368
import img10_1 from "../assets/images/img1-1.jpg"; // 555x368
import img11 from '../assets/images/img11.jpg'; // 1140x594
import img12 from "../assets/images/team-8.jpg"; // 360x453
import img13 from "../assets/images/img3.jpg"; // 360x240
import img14 from "../assets/images/img15.jpg"; // 1140x608
import slider1 from "../assets/images/img12.jpg"; // 750x469
import img15 from "../assets/images/img22.jpg"; // 350x256
import errorimg from "../assets/images/404-img3.gif"; // 550x476
import AssessoriaEmTiPrincipal from "../assets/images/assessoria-em-ti-imagem-principal.webp"
import AssessoriaEmTiSecundaria from '../assets/images/assessoria-em-ti-imagem-secundaria.png';
import ClientBemViver from "../assets/images/ClientBemViver.png";
import ClientIBICT from "../assets/images/ClientIBICT.png";
import ClientMCTI from "../assets/images/ClientMCTI.png";
import ClientOowlish from "../assets/images/ClientOowlish.png";
import ClientPraKaranga from "../assets/images/ClientPraKaranga.png";
import ClientRnp from "../assets/images/ClientRnp.png";
import ClientSoftplan from "../assets/images/ClientSoftplan.png";
import PartnerOmniSec from "../assets/images/PartnerOmniSec.png";
import PartnerYouLead from "../assets/images/PartnerYouLead.png";
import Founder from "../assets/images/Founder.png";
import chartimg from "../assets/images/chart-bar.png";
import category1 from "../assets/images/shopimg.jpg"; // 570x290
import Post5Sinais from '../assets/images/blog/5-sinais-chamada.png';
import PostComoAssessoria from '../assets/images/blog/como-a-assessoria-chamada.png';
import PostOPapel from '../assets/images/blog/o-papel-chamada.png';
import OQueFazemosImg1 from '../assets/images/o-que-fazemos-imagem-1.png';
import OQueFazemosImg2 from '../assets/images/o-que-fazemos-imagem-2.png';
import QuemSomosImg1 from '../assets/images/quem-somos-imagem-1.png';
import SoftwareHouseImgPrincipal from '../assets/images/software-house-principal.png';
import SoftwareHouseImgSecundaria from '../assets/images/software-house-secundaria.png';
import CountUp from "react-countup";
import footerLogo from "../assets/images/logo2.png";
import {GiCutDiamond} from "react-icons/gi";

import DERRICOLogoBranco from '../assets/images/Derrico Consultoria Logotipo Branco.png';
import DERRICOSolucoes from '../assets/images/DERRICO-Solucoes-em-TI-&-IA.webp';
import DERRICOWebDevelopment from '../assets/images/DERRICOWebDevelopment.webp';
import DERRICOMobileDevelopment from '../assets/images/DERRICOMobileDevelopment.webp';
import DERRICOExperience from '../assets/images/DERRICOExperience.webp';
import DERRICOTecnologyDomain from '../assets/images/DERRICOTecnologyDomain.webp';
import DERRICOBlog from '../assets/images/DERRICOBlog.webp'
import DERRICODigitalSolution from '../assets/images/DERRICODigitalSolution.webp';
import DERRICOBestResults from '../assets/images/DERRICOBestResults.webp';
import DERRICOProofExpertise from '../assets/images/DERRICOProofExpertise.webp';
import DERRICOOnlineAttending from '../assets/images/DERRICOOnlineAttending.webp';
import DERRICOInovation from '../assets/images/DERRICOInovation.webp';
import DERRICOCustomizedSolutions from '../assets/images/DERRICOCustomizedSolutions.webp';
import {GrGithub, GrOracle} from "react-icons/gr";
import {CgBitbucket} from "react-icons/cg";
import {FcIpad} from "react-icons/fc";
import {GoCode, GoX} from "react-icons/go";

const sectiondata = {
    HeaderData: {
        HeaderBar: {
            phoneNumber: '+55 61 3046-0200',
            email: 'contato@derrico.tec.br',
            location: 'Brasília DF, Brasil',
            socialLists: [
                {
                    icon: <FaLinkedinIn />,
                    url: 'https://www.linkedin.com/company/derrico'
                },
                {
                    icon: <FaInstagram />,
                    url: 'https://www.instagram.com/derrico.solucoes'
                },
                {
                    icon: <FaFacebookF />,
                    url: 'https://facebook.com/derrico.solucoes'
                },
                {
                    icon: <FaYoutube />,
                    url: 'https://www.youtube.com/@derrico.solucoes'
                }
            ],
            languages: [
                // {
                //     name: 'Português',
                //     url: '#',
                //     language: 'pt'
                // },
                // {
                //     name: 'English',
                //     url: '#',
                //     language: 'en'
                // }
            ],
            // btntext: "Trabalhe Conosco",
            // btnicon: <FiChevronRight className="icon" />,
            // btnurl: '#'
        },
        HeaderMenuBar: {
            logourl: logo,
            menus: [
                {
                    title: 'Home',
                    path: '/',
                },
                {
                    title: 'Sobre nós',
                    path: '#',
                    dropdown: [
                        {
                            title: 'Quem somos',
                            path: '/quem-somos'
                        },
                        {
                            title: 'O que fazemos',
                            path: '#',
                            dep_dropdown: [
                                {
                                    title: 'Assessoria em TI',
                                    path: '/assessoria-em-ti',
                                },
                                {
                                    title: 'Software House',
                                    path: '/software-house',
                                },
                                {
                                    title: 'Observability',
                                    path: '/observability',
                                },
                                {
                                    title: 'Mentorias',
                                    path: '/mentorias',
                                }
                            ]
                        },
                        {
                            title: 'Como fazemos',
                            path: '/#',
                            dep_dropdown: [
                                {
                                    title: 'Linguagens',
                                    path: '/linguagens',
                                },
                                {
                                    title: 'Serviços de Nuvem',
                                    path: '/servicos-de-nuvem',
                                },
                                {
                                    title: 'Lojas de Aplicativos',
                                    path: '/lojas-de-aplicativos',
                                },
                                {
                                    title: 'Soluções de Integração',
                                    path: '/solucoes-de-integracao',
                                },
                                {
                                    title: 'Meios de Pagamento',
                                    path: '/meios-de-pagamento',
                                },
                                {
                                    title: 'Gerenciamento de Projetos',
                                    path: '/gerenciamento-de-projetos',
                                }
                            ]
                        },
                    ]
                },
                // {
                //     title: 'Produtos',
                //     path: '#',
                //     dropdown: [
                //         {
                //             title: 'WAIA',
                //             path: '/waia'
                //         },
                //         {
                //             title: '+Comida',
                //             path: '/mais-comida'
                //         },
                //         {
                //             title: 'All API',
                //             path: '/all-api'
                //         },
                //         {
                //             title: 'DigJobs',
                //             path: '/dig-jobs'
                //         },
                //     ]
                // },
                {
                    title: 'Parceiros',
                    path: '/parceiros-derrico'
                },
                // {
                //     title: 'Links Úteis',
                //     path: '#',
                //     dropdown: [
                //         // {
                //         //     title: 'APIs',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Consultar CNPJ',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Consultar CEP',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         {
                //             title: 'Conversores',
                //             // path: '/team-grid',
                //             dep_dropdown: [
                //                 {
                //                     title: 'Base64',
                //                     path: 'https://www.4devs.com.br/codificar_decodificar_base64',
                //                 },
                //                 {
                //                     title: 'JWT',
                //                     path: 'https://jwt.io',
                //                 },
                //                 {
                //                     title: 'REGEX',
                //                     path: 'https://regex101.com',
                //                 },
                //                 {
                //                     title: 'World Time',
                //                     path: 'https://www.worldtimebuddy.com',
                //                 },
                //                 {
                //                     title: 'Timestamp',
                //                     path: 'https://www.epochconverter.com',
                //                 },
                //                 {
                //                     title: 'URL',
                //                     path: 'https://www.urldecoder.org',
                //                 }
                //             ]
                //         },
                //         {
                //             title: 'Designer Tools',
                //             // path: '/team-grid',
                //             dep_dropdown: [
                //                 {
                //                     title: 'Draw.IO',
                //                     path: 'https://www.draw.io/',
                //                 },
                //                 {
                //                     title: 'Excalidraw',
                //                     path: 'https://excalidraw.com/',
                //                 },
                //                 {
                //                     title: 'Figma',
                //                     path: 'https://www.figma.com/',
                //                 }
                //             ]
                //         },
                //         // {
                //         //     title: 'Geradores',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Gerenciamento',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Governo',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'IA',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Meios Pagamento',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Consultar CNPJ',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Consultar CEP',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Nuvens',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Redes Sociais',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Reuniões',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // },
                //         // {
                //         //     title: 'Trabalhista',
                //         //     path: '/team-grid',
                //         //     dep_dropdown: [
                //         //         {
                //         //             title: 'Microsoft Teams',
                //         //             path: '/team-grid',
                //         //         },
                //         //         {
                //         //             title: 'Google Meet',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Zoom',
                //         //             path: '/team-details',
                //         //         },
                //         //         {
                //         //             title: 'Jitsi',
                //         //             path: '/team-details',
                //         //         }
                //         //     ]
                //         // }
                //     ]
                // },
                {
                    title: 'Blog',
                    path: '/blog-derrico',
                },
                {
                    title: 'Contato',
                    path: '/contato',
                }
                // {
                //     title: 'Portfolio',
                //     path: '/portfolio-grid',
                //     dropdown: [
                //         {
                //             title: 'Portfolio Grid',
                //             path: '/portfolio-grid',
                //         },
                //         {
                //             title: 'Portfolio Details',
                //             path: '/portfolio-details',
                //         }
                //     ]
                // },
                // {
                //     title: 'Blog',
                //     path: '/blog-grid',
                    // dropdown: [
                    //     {
                    //         title: 'Blog Full Width',
                    //         path: '/blog-full-width',
                    //     },
                    //     {
                    //         title: 'Blog Grid',
                    //         path: '/blog-grid',
                    //     },
                    //     {
                    //         title: 'Blog Details',
                    //         path: '/blog-details',
                    //     }
                    // ]
                // },
                // {
                //     title: 'Shop',
                //     path: '/shop-home',
                //     dropdown: [
                //         {
                //             title: 'Shop Home',
                //             path: '/shop-home',
                //         },
                //         {
                //             title: 'Shop Sidebar',
                //             path: '/shop',
                //         },
                //         {
                //             title: 'Product Details',
                //             path: '/product-details',
                //         },
                //         {
                //             title: 'Cart',
                //             path: '/cart',
                //         },
                //         {
                //             title: 'Checkout',
                //             path: '/checkout',
                //         }
                //     ]
                // },
            ]
        }
    },
    Sliders: {
        heroSlider1: [
            {
                title: 'Inovação e tecnologia para impulsionar o sucesso do seu negócio',
                description: 'Com soluções digitais sob medida, projetadas para se integrar sem esforço ao seu modelo de negócios, garantimos uma transição suave. Seja online ou com o suporte da nossa estrutura física, estamos sempre ao seu lado. Escolha crescer, escolha inovar, escolha resultados excepcionais. Escolha nossa parceria e veja sua empresa prosperar.',
                button1: 'Vamos inovar juntos',
                button1url: '/contato',
                bg: DERRICOSolucoes,
                col: '10'
            },
            // {
            //     title: 'Soluções Personalizadas, Resultados Transformadores',
            //     description: 'Transforme suas ideias em realidade com nossos serviços de desenvolvimento de software sob medida. Nos especializamos na criação de aplicações escaláveis e eficientes, feitas sob medida para atender às necessidades únicas do seu negócio, impulsionando inovação e crescimento.',
            //     button1: 'Vamos Inovar Juntos',
            //     button1url: '/contato',
            //     bg: DERRICOWebDevelopment,
            //     col: '10'
            // },
            // {
            //     title: 'Mobilize Sua Estratégia Digital',
            //     description: 'Mantenha-se à frente no mundo mobile com nossos serviços abrangentes de desenvolvimento mobile. Seja iOS ou Android, projetamos e desenvolvemos aplicações suaves e fáceis de usar que mantêm seus usuários engajados e conectados.',
            //     button1: 'Inicie Seu Projeto',
            //     button1url: '/contato',
            //     bg: DERRICOMobileDevelopment,
            //     col: '10'
            // },
            // {
            //     title: 'Experiência e Inovação ao Seu Alcance',
            //     description: 'Com anos de experiência em diversos segmentos, oferecemos uma infraestrutura robusta e conhecimento aprofundado em tecnologia digital para superar suas expectativas. Estamos equipados para fornecer soluções inovadoras e personalizadas que impulsionam o sucesso e a satisfação do cliente.',
            //     button1: 'Faça Parte',
            //     button1url: '/clients',
            //     bg: DERRICOExperience,
            //     col: '12'
            // },
            // {
            //     title: 'Domínio Tecnológico para Cada Desafio',
            //     description: 'Na DERRICO, transformamos complexidade em soluções sob medida. Com vasto conhecimento em tecnologias de ponta, estamos prontos para atender qualquer necessidade e impulsionar seu sucesso.',
            //     button1: 'Conheça Nosso Domínio',
            //     button1url: '/services',
            //     bg: DERRICOTecnologyDomain,
            //     col: '6'
            // },
            // {
            //     title: 'Venha Descobrir as Últimas Tendências com o Blog da DERRICO!',
            //     description: 'Mergulhe em insights profundos e conteúdo relevante que vai além das expectativas. No Blog da DERRICO, exploramos as tecnologias mais recentes, trazemos dicas de especialistas e compartilhamos histórias que inspiram inovação. Clique aqui e fique por dentro do que há de mais novo no mundo da tecnologia!',
            //     button1: 'Acessar Blog',
            //     button1url: '/blog-grid',
            //     bg: DERRICOBlog,
            //     col: 12
            // }
        ],
        testimonial: [
            {
                name: "Tanbir_Ahmed",
                city: "United States",
                img: img1,
                desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme.",
            },
            {
                name: "Mark_Doe",
                city: "United States",
                img: img1,
                desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme."
            },
            {
                name: "Rizon_Pet",
                city: "United States",
                img: img1,
                desc: "Enjoy the diversity of ThePin. Check this numerous demos, made for different purposes. Easy to import & highly customizable. All of the presented elements, layouts & styles can be theme.",
            }
        ],
        clientlogos: [
            {
                img: ClientBemViver
            },
            {
                img: ClientIBICT
            },
            {
                img: ClientMCTI
            },
            {
                img: ClientOowlish
            },
            {
                img: ClientPraKaranga
            },
            {
                img: ClientRnp
            },
            {
                img: ClientSoftplan
            },
            {
                img: PartnerOmniSec
            },
            {
                img: PartnerYouLead
            }
        ],
        heroSlider2: [
            {
                title: 'Minzel Gives You Power to Grow Your Business.',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.',
                button1: 'Get Started',
                button2: 'Learn More',
                button1url: '/',
                button2url: '/',
                bg: herosliderbg,
                col: '8'
            },
            {
                title: 'Your Business Success is Our Success.',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.',
                button1: 'Let\'s Start Now',
                button2: 'Learn More',
                button1url: '/',
                button2url: '/',
                bg: herosliderbg,
                col: '8 offset-2 text-center'
            },
            {
                title: 'Work With World Class Finance Experts.',
                description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem veritatis et quasi architecto beatae vitae dicta.',
                button1: 'Let\'s Start Now',
                button2: 'Learn More',
                button1url: '/',
                button2url: '/',
                bg: herosliderbg,
                col: '8'
            }
        ],
        shopheroslider: [
            {
                title: 'Hand Picked Items Sold Exclusively Online.',
                description: "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
                button1: 'Shop Now',
                button2: 'All Products',
                button1url: '/shop',
                button2url: '/shop',
                bg: herosliderbg,
                col: '8'
            },
            {
                title: 'Find That Perfect Gift and Surprise Someone.',
                description: "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
                button1: 'Shop Now',
                button2: 'All Products',
                button1url: '/shop',
                button2url: '/shop',
                bg: herosliderbg,
                col: '8 offset-2 text-center'
            },
            {
                title: 'Beautifully Design Pieces by  Talented Designers..',
                description: "Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum dolor sit amet",
                button1: 'Shop Now',
                button2: 'All Products',
                button1url: '/shop',
                button2url: '/shop',
                bg: herosliderbg,
                col: '8'
            }
        ]
    },
    Services: {
        LinguagensHighCode: {
            secTitle: 'High Code',
            secSubtitle: 'Linguagens',
            serviceLists: [
                {
                    icon: <DiJavascript className="service-items-icon" />,
                    title: 'Javascript',
                    description: 'O Javascript é uma linguagem de programação. Seu ecossistema atual tem muitas bibliotecas e frameworks, práticas de programação estabelecidas e aumento do uso fora dos navegadores da Web.',
                    showbtn: false,
                },
                {
                    icon: <DiDotnet className="service-items-icon" />,
                    title: 'dotNET',
                    description: '.NET é uma plataforma de desenvolvimento gratuita e de software livre para a criação de muitos tipos de aplicativos, como Aplicativos Web, APIs Web e Microsserviços, entre outros.',
                    showbtn: false,
                },
                {
                    icon: <DiJava className="service-items-icon" />,
                    title: 'Java',
                    description: 'Java é uma linguagem de programação orientada a objetos, conhecida por "escreva uma vez, execute em qualquer lugar". É usada para desenvolver aplicativos mobile, empresarial e sistemas embarcados.',
                    showbtn: false,
                },
                {
                    icon: <DiPython className="service-items-icon" />,
                    title: 'Python',
                    description: 'Python é uma linguagem de programação de alto nível, famosa por sua clareza e simplicidade. É amplamente utilizada em ciência de dados, desenvolvimento web e automação.',
                    showbtn: false,
                },
                {
                    icon: <DiPhp className="service-items-icon" />,
                    title: 'Php',
                    description: 'PHP é uma linguagem de script do lado do servidor, amplamente utilizada para o desenvolvimento web. É ideal para criar sites dinâmicos e interativos, suportando vários bancos de dados e integrando-se facilmente com HTML.',
                    showbtn: false,
                },
                {
                    icon: <SiTypescript className="service-items-icon" />, // Usando o ícone DiJavascript1 (pode ser outro mais adequado, como um específico para TypeScript, se existir na biblioteca que você usa)
                    title: 'TypeScript',
                    description: 'TypeScript é um superset de JavaScript que adiciona tipagem estática. Melhora a manutenibilidade, escalabilidade e detecção de erros em projetos JavaScript, sendo amplamente utilizado no desenvolvimento front-end e back-end.',
                    showbtn: false,
                },
            ],
            about: {
                content: [
                    'Você já ouviu falar em **linguagens High Code**? Se o mundo da programação parece um bicho de sete cabeças, relaxa! A gente vai te explicar de forma simples e direta o que isso significa e, principalmente, por que você deveria se importar com isso para o seu negócio.',
                    'Linguagens High Code (ou de alto nível) são como **"tradutores"** entre nós, humanos, e os computadores. Em vez de usar códigos complexos e cheios de números, elas usam uma linguagem mais próxima do nosso dia a dia, com palavras e expressões que fazem mais sentido pra gente.',
                ],
                images: [
                    'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/linguagens-high-code.jpg',
                ],
            },
        },
        PlataformasLowCode: {
            secTitle: 'Low Code',
            secSubtitle: 'Plataformas',
            serviceLists: [
                {
                    icon: <SiFlutter className="service-items-icon" />,
                    title: 'FlutterFlow',
                    description: 'FlutterFlow é uma ferramenta Low-Code para desenvolvimento visual de aplicativos móveis usando o framework Flutter. Agiliza a criação de interfaces e lógica de negócios, oferecendo flexibilidade com código Dart.',
                    showbtn: false,
                },
                {
                    icon: <SiPowerapps className="service-items-icon" />,
                    title: 'Power Apps',
                    description: 'Power Apps da Microsoft é uma plataforma Low-Code para criar aplicativos de negócios personalizados. Integra-se ao ecossistema Microsoft e utiliza expressões e fórmulas para lógica avançada.',
                    showbtn: false,
                },
                {
                    icon: <SiXamarin className="service-items-icon"/>,
                    title: 'Xano',
                    description: 'Xano é uma plataforma Backend Low-Code que permite criar backends, APIs e bancos de dados sem código, ideal para integração com front-ends Low-Code, oferecendo opções de extensibilidade com código customizado.',
                    showbtn: false,
                },
            ],
            about: {
                content: [
                    'Já ouviu falar em **Low Code**? Se o desenvolvimento de software parece complicado, fique tranquilo! Vamos te mostrar de forma clara o que isso significa e, crucialmente, por que é importante para a sua empresa.',
                    'Plataformas Low Code são como **"construtores rápidos"** de aplicativos. Ao invés de escrever milhares de linhas de código complexo, elas oferecem interfaces visuais, com blocos pré-construídos e ferramentas de arrastar e soltar, permitindo criar soluções digitais de maneira mais ágil e eficiente.',
                ],
                images: [
                    'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/plafaformas-low-code.jpg',
                ],
            },
        },
        PlataformasNoCode: {
            secTitle: 'No-Code',
            secSubtitle: 'Plataformas',
            serviceLists: [
                {
                    icon: <SiWebflow className="service-items-icon" />,
                    title: 'Webflow',
                    description: 'Webflow é uma plataforma No-Code para design e desenvolvimento web responsivo. Permite criar sites complexos visualmente, sem escrever código.',
                    showbtn: false,
                },
                {
                    icon: <GoCode className="service-items-icon" />,
                    title: 'Bubble',
                    description: 'Bubble é uma poderosa plataforma No-Code para criar aplicações web completas, desde protótipos simples até aplicações robustas, sem necessidade de código.',
                    showbtn: false,
                },
                {
                    icon: <SiZapier className="service-items-icon" />,
                    title: 'Zapier',
                    description: 'Zapier é uma ferramenta de automação No-Code que conecta diferentes aplicativos web, automatizando tarefas repetitivas e fluxos de trabalho.',
                    showbtn: false,
                },
                {
                    icon: <SiMake className="service-items-icon" />,
                    title: 'Make',
                    description: 'Make é uma plataforma No-Code para integração e automação de processos, permitindo a criação de cenários complexos com diversas aplicações.',
                    showbtn: false,
                },
            ],
            about: {
                content: [
                    'Você já ouviu falar em **plataformas No-Code**? Se a ideia de programar parece complexa, não se preocupe! Explicaremos de forma simples e direta o que são essas plataformas e como elas podem ajudar o seu negócio.',
                    'Plataformas No-Code permitem a criação de aplicativos, sites e automações sem a necessidade de escrever código. Elas oferecem **interfaces visuais** intuitivas, onde você pode arrastar e soltar elementos, configurar fluxos de trabalho e construir soluções digitais de forma ágil e eficiente.',
                ],
                images: [
                    'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/plataformas-no-code.jpg',
                ],
            },
        },
        Frameworks: {
            secTitle: 'Frameworks',
            secSubtitle: 'Tecnologias',
            serviceLists: [
                {
                    icon: <DiLaravel className="service-items-icon" />,
                    title: 'Laravel',
                    description: 'Laravel é um framework PHP para desenvolvimento web, focado em simplicidade e elegância. Oferece ferramentas robustas para aplicações modernas, com ênfase em código limpo e funcionalidades ricas.',
                    showbtn: false,
                },
                {
                    icon: <FaCoffee className="service-items-icon" />,
                    title: 'Spring Boot',
                    description: 'Spring Boot é um framework Java que facilita a criação de aplicações web e microserviços de maneira rápida e eficiente, oferecendo configuração automática e uma vasta gama de funcionalidades prontas para uso.',
                    showbtn: false,
                },
                {
                    icon: <FaMobileAlt className="service-items-icon" />,
                    title: 'Cordova',
                    description: 'Cordova é um framework de desenvolvimento mobile que permite criar aplicativos usando HTML, CSS e JavaScript, convertidos em aplicativos nativos para plataformas como iOS e Android, facilitando o desenvolvimento com um único código.',
                    showbtn: false,
                },
                {
                    icon: <DiReact className="service-items-icon" />,
                    title: 'React Native',
                    description: 'React Native é um framework de desenvolvimento mobile que usa JavaScript para criar aplicativos nativos para iOS e Android. Permite um desenvolvimento eficiente com um código-base compartilhado entre plataformas.',
                    showbtn: false,
                },
                {
                    icon: <FaCode className="service-items-icon" />,
                    title: 'ASP.NET Core',
                    description: 'Um framework para desenvolvimento de aplicações web e serviços web. É leve, de alto desempenho e pode ser usado para construir aplicações modernas, tanto no lado do servidor quanto em ambientes de nuvem.',
                    showbtn: false,
                },
                {
                    icon: <FaDatabase className="service-items-icon" />,
                    title: 'Entity',
                    description: 'Um framework de mapeamento objeto-relacional para .NET, usado para acessar e gerenciar dados de bancos de dados usando objetos .NET. Facilita a interação com o banco de dados, sem necessitar escrever consultas SQL manualmente.',
                    showbtn: false,
                },
                {
                    icon: <DiDjango className="service-items-icon" />,
                    title: 'Django',
                    description: 'Django é um framework Python \'completo\' para desenvolvimento rápido de aplicações web seguras e escaláveis, ideal para projetos complexos.',
                    showbtn: false,
                },
                {
                    icon: <DiCode className="service-items-icon" />,
                    title: 'Flask',
                    description: 'Flask é um framework Python leve e flexível, perfeito para desenvolver aplicações web simples ou para projetos que necessitam de personalização.',
                    showbtn: false,
                },
                {
                    icon: <SiPypi className="service-items-icon" />,
                    title: 'Fast API',
                    description: 'FastAPI é um framework Python moderno e de alto desempenho, projetado para criar APIs web eficientes e fáceis de documentar, usando padrões modernos.',
                    showbtn: false,
                },
            ],
            about: {
                content: [
                    'Você já ouviu falar em **linguagens High Code**? Se o mundo da programação parece um bicho de sete cabeças, relaxa! A gente vai te explicar de forma simples e direta o que isso significa e, principalmente, por que você deveria se importar com isso para o seu negócio.',
                    'Linguagens High Code (ou de alto nível) são como **"tradutores"** entre nós, humanos, e os computadores. Em vez de usar códigos complexos e cheios de números, elas usam uma linguagem mais próxima do nosso dia a dia, com palavras e expressões que fazem mais sentido pra gente.',
                ],
                images: [
                    QuemSomosImg1,
                ],
            },
        },
        IDEs: {
            secTitle: 'IDEs e Ferramentas para Desenvolvimento',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <SiWebstorm className="service-items-icon" />,
                    title: 'WebStorm',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPhp className="service-items-icon" />,
                    title: 'PHPStorm',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPython className="service-items-icon" />,
                    title: 'PyCharm',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiIntellijidea className="service-items-icon" />,
                    title: 'IntelliJ',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiJetbrains className="service-items-icon" />,
                    title: 'Rider',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaDatabase className="service-items-icon" />,
                    title: 'DataGrip',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiVisualstudiocode className="service-items-icon" />,
                    title: 'VSCode',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiVisualstudio className="service-items-icon" />,
                    title: 'Visual Studio',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiXcode className="service-items-icon" />,
                    title: 'XCode',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiAndroidstudio className="service-items-icon" />,
                    title: 'Android Studio',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPostman className="service-items-icon" />,
                    title: 'Postman',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftsqlserver className="service-items-icon" />,
                    title: 'SQL Server Management Studio',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaProjectDiagram className="service-items-icon" />,
                    title: 'PowerDesigner',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiDocker className="service-items-icon" />,
                    title: 'Docker',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiRancher className="service-items-icon" />,
                    title: 'Rancher',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiKubernetes className="service-items-icon" />,
                    title: 'Kubernetes',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
            ]
        },
        Database: {
            secTitle: 'Banco de Dados Relacionais e NoSQL',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <DiMysql className="service-items-icon" />,
                    title: 'MySQL',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <GrOracle className="service-items-icon" />,
                    title: 'Oracle',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiPostgresql className="service-items-icon" />,
                    title: 'PostgreSQL',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftsqlserver className="service-items-icon" />,
                    title: 'SQL Server',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiElasticsearch className="service-items-icon" />,
                    title: 'Elasticsearch',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiMongodb className="service-items-icon" />,
                    title: 'MongoDB',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },

            ]
        },
        SOCloud: {
            secTitle: 'Sistemas Operacionais e Nuvens',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <FaApple className="service-items-icon" />,
                    title: 'MacOS',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiIos className="service-items-icon" />,
                    title: 'iOS',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FcIpad className="service-items-icon" />,
                    title: 'iPadOS',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoft className="service-items-icon" />,
                    title: 'Windows',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiLinux className="service-items-icon" />,
                    title: 'Linux',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftazure className="service-items-icon" />,
                    title: 'Microsoft Azure',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiAmazon className="service-items-icon" />,
                    title: 'Amazon AWS',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiDigitalOcean className="service-items-icon" />,
                    title: 'Digital Ocean',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
            ]
        },
        Gerenciamento: {
            secTitle: 'Gerenciamento de Códigos e Projetos',
            secSubtitle: 'Tecnologias',
            // secButton: 'Exibir Todos Serviços',
            // secButtonUrl: '/',
            serviceLists: [
                {
                    icon: <FaGit className="service-items-icon" />,
                    title: 'Git',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaGitter className="service-items-icon" />,
                    title: 'GitFlow',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <FaGitlab className="service-items-icon" />,
                    title: 'GitLab',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <GrGithub className="service-items-icon" />,
                    title: 'GitHub',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <CgBitbucket className="service-items-icon" />,
                    title: 'BitBucket',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <DiJira className="service-items-icon" />,
                    title: 'Jira',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftteams className="service-items-icon" />,
                    title: 'Microsoft Teams',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiMicrosoftsharepoint className="service-items-icon" />,
                    title: 'Microsoft Sharepoint',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiGooglehangouts className="service-items-icon" />,
                    title: 'Google Meet',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
                {
                    icon: <SiClockify className="service-items-icon" />,
                    title: 'Clockify',
                    titleurl: '/service-details',
                    description: '',
                    showbtn: false,
                    buttontext: 'Learn More',
                    buttonurl: '/service-details',
                },
            ]
        },
        ComoFazemos: {
            secTitle: 'Como fazemos',
            serviceLists: [
                {
                    icon: <FiCode className="service-items-icon" />,
                    title: 'Linguagens',
                    description: 'High Code para controle total e flexibilidade, Low Code para agilidade com personalização e No Code para interfaces intuitivas e rápidas.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/linguagens',
                },
                {
                    icon: <FiCloud className="service-items-icon" />,
                    title: 'Serviços de nuvem',
                    description: 'Oferecemos suporte em Cloud Services, integrando infraestrutura, escalabilidade e segurança para otimizar aplicações e dados na nuvem.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/servicos-de-nuvem',
                },
                {
                    icon: <FiShoppingBag className="service-items-icon" />,
                    title: 'Lojas de Aplicativos',
                    description: 'Cuidamos de todo o processo de publicação de apps na App Store e Google Play, garantindo conformidade, otimização e visibilidade.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/lojas-de-aplicativos',
                },
                {
                    icon: <IoIosBuild className="service-items-icon" />,
                    title: 'Soluções de Integração',
                    description: 'Integramos sistemas com APIs, IA, Keycloak, segurança e monitoramento, garantindo performance e confiabilidade.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/solucoes-de-integracao',
                },
                {
                    icon: <AiOutlineDollar className="service-items-icon" />,
                    title: 'Meios de Pagamento',
                    description: 'Implementamos meios de pagamento com checkout transparente ou links, integrando soluções como EFI Bank, Pagar.me.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/meios-de-pagamento',
                },
                {
                    icon: <FiClipboard className="service-items-icon" />,
                    title: 'Gerenciamento de Projetos',
                    description: 'Gerenciamos projetos com ferramentas de versionamento, colaboração e controle, garantindo organização e eficiência.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/gerenciamento-de-projetos',
                },
                {
                    icon: <FiCode className="service-items-icon" />,
                    title: 'Frameworks',
                    description: 'Dominamos um amplo leque de frameworks para entregar soluções digitais personalizadas e eficazes.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/frameworks',
                },
                {
                    icon: <FiDatabase className="service-items-icon" />,
                    title: 'Bancos de Dados',
                    description: 'Implementamos soluções de bancos de dados eficientes e escaláveis, garantindo a integridade e segurança dos dados.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/bancos-de-dados',
                },
                {
                    icon: <FaTools className="service-items-icon" />,
                    title: 'Ferramentas',
                    description: 'Utilizamos as melhores ferramentas para otimizar o desenvolvimento e a entrega de seus projetos.',
                    showbtn: true,
                    buttontext: 'Saiba mais',
                    buttonurl: '/ferramentas',
                },
            ]
        },
        NossosProdutos: {
            sectitle: 'Produtos inovadores para simplificar a vida',
            secsubtitle: 'Soluções que Transformam Negócios com Tecnologia Inteligente',
            content: 'Estamos desenvolvendo uma linha de produtos inovadores para revolucionar o mercado: inteligência artificial integrada ao WhatsApp Business para um atendimento personalizado, sistemas ERP que reimaginam a gestão alimentícia, APIs que agregam valor ao seu negócio com eficiência e plataformas inteligentes de recrutamento para o setor de TI. Cada solução está sendo projetada para atender às suas necessidades e criar novas oportunidades para o seu crescimento.',
            buttontext: 'Saiba mais',
            buttonurl: '/contato',
            lists: [
                {
                    icon: <AiOutlineMessage />,
                    title: "WAIA",
                    titleurl: '/service-details',
                    desc: "Inteligência artificial generativa integrada com WhatsApp Business personalizada para atender exclusivamente cada cliente"
                },
                {
                    icon: <FiBookOpen />,
                    title: "+Comida",
                    titleurl: '/service-details',
                    desc: "Sistema ERP no segmento alimentício com foco na experiência do usuário, para micro, pequenos, médios e grande estabelecimentos"
                },
                {
                    icon: <FiServer />,
                    title: "All API",
                    titleurl: '/service-details',
                    desc: "Site que oferece uma gama enorme de serviços via API, agregando valor ao negócio de forma simples, eficaz e com preço justo."
                },
                {
                    icon: <FiUsers />,
                    title: "DigJobs",
                    titleurl: '/service-details',
                    desc: "Sistema de Recrutamento e Seleção voltado para o segmento de TI, amigável, personalizável e de custo acessível para as empresas."
                }

            ]
        },
        MissaoVisaoValor: [
            {
                icon: <FiTarget className="service-items-icon" />,
                title: 'Nossa Missão',
                description: 'Impulsionar negócios por meio de soluções tecnológicas robustas e escaláveis, priorizando inovação, eficiência e personalização. Nossa missão é ajudar empresas a crescer e prosperar, fornecendo produtos que atendam às demandas de um mercado em constante evolução.',
            },
            {
                icon: <IoIosNotificationsOutline className="service-items-icon" />,
                title: 'Nossa Visão',
                description: 'Ser reconhecida como referência em desenvolvimento de soluções tecnológicas, criando parcerias duradouras e confiáveis. Almejamos transformar a interação das empresas com a tecnologia, estabelecendo padrões que gerem impacto positivo e sustentável.',
            },
            {
                icon: <FaHandshake className="service-items-icon" />,
                title: 'Nossos Valores',
                description: 'Ética, bom atendimento, transparência, organização e seriedade são os pilares que norteiam nosso trabalho. Comprometemo-nos com a excelência técnica, relações sólidas e o sucesso dos nossos clientes, entregando soluções que não apenas atendem, mas superam as expectativas.',
            }
        ],
        // serviceVideo: {
        //     videoId: 't3tsMEhlvwM', // Enter YouTube Video's id here
        //     videobutton: 'Watch',
        //     img: img11
        // },
        // serviceDetails: {
        //     sliders: [
        //         slider1,
        //         slider1,
        //         slider1,
        //     ],
        //     title: 'International Business Opportunities.',
        //     content1: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered altera tion in some form, by injected humour, or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn’t anything embarrassing hidden.',
        //     content2: 'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.',
        //     features: [
        //         {
        //             title: 'Seed do eiusmod tempor incididunt ut',
        //             icon: <FiCheckCircle className="icon" />
        //         },
        //         {
        //             title: 'Exercitation ullamco laboris nis.',
        //             icon: <FiCheckCircle className="icon" />
        //         },
        //         {
        //             title: 'Quis nostrud exerc citation.',
        //             icon: <FiCheckCircle className="icon" />
        //         },
        //         {
        //             title: 'Andomised words which don\'t look',
        //             icon: <FiCheckCircle className="icon" />
        //         },
        //         {
        //             title: 'Commodo consequat duis autex.',
        //             icon: <FiCheckCircle className="icon" />
        //         },
        //         {
        //             title: 'Andomised words which don\'t look',
        //             icon: <FiCheckCircle className="icon" />
        //         },
        //         {
        //             title: 'All the Lorem ipsum generators',
        //             icon: <FiCheckCircle className="icon" />
        //         },
        //     ],
        //     featuresimg: img13,
        //     plans: {
        //         img: img13,
        //         title: 'Planning Strategy',
        //         content: 'Adipisicing elit, sed do eiusmod tempor incididunt ul labore et dolore magna aliqua',
        //         lists: [
        //             {
        //                 title: 'research beyond the business plan',
        //                 icon: <FiCheckCircle className="icon" />
        //             },
        //             {
        //                 title: 'marketing options and rates.',
        //                 icon: <FiCheckCircle className="icon" />
        //             },
        //             {
        //                 title: 'the ability to turnaround consulting.',
        //                 icon: <FiCheckCircle className="icon" />
        //             },
        //         ]
        //     },
        //     project: {
        //         title: 'Project Results.',
        //         content1: 'There are many variations of passages of Lorem ipsum available, but the majority have suffered altera tion in some form, by injected,',
        //         content2: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. eu fugiat nulla pariatur.',
        //         content3: 'Enim minim veniam quis nostrud. exercitation ullamco laboris nisi aliquip'
        //     },
        //     sidebar: {
        //         categories: {
        //             title: 'Categories.',
        //             lists: [
        //                 {
        //                     title: 'Finance Management',
        //                     path: '/service-details',
        //                 },
        //                 {
        //                     title: 'Banking Investigation',
        //                     path: '/service-details',
        //                 },
        //                 {
        //                     title: 'Business Insurance',
        //                     path: '/service-details',
        //                 },
        //                 {
        //                     title: 'Car Insurance',
        //                     path: '/service-details',
        //                 },
        //                 {
        //                     title: 'Market Research',
        //                     path: '/service-details',
        //                 },
        //                 {
        //                     title: 'Home Insurance',
        //                     path: '/service-details',
        //                 },
        //                 {
        //                     title: 'Life Insurance',
        //                     path: '/service-details',
        //                 },
        //                 {
        //                     title: 'Health Insurance',
        //                     path: '/service-details',
        //                 },
        //             ]
        //         },
        //         contactinfos: {
        //             title: 'Contact Us.',
        //             address: '121 King Street, USA, New York',
        //             phonenumber: '+1401574423',
        //             email: 'example@support.com'
        //         },
        //         broucher: {
        //             title: 'Brochures',
        //             content: 'View our 2020 financial prospectus brochure for an easy to read guide on all of the services offer.',
        //             button: 'Company Presentation',
        //             buttonurl: '#', // Enter download file url here
        //         }
        //     }
        // }
    },
    Aboutdata: {
        about1: {
            title: 'Quem somos',
            subtitle: 'Soluções em TI & IA',
            content: 'Com uma trajetória rica e diversificada, nós da DERRICO, oferecemos soluções de Tecnologia da Informação altamente especializadas para projetos em âmbito local e internacional. Nossos serviços cobrem uma ampla gama de setores, tanto governamentais quanto privados, garantindo a adaptação e eficácia necessárias para cada contexto. Nossa expertise abrange uma variedade de áreas, desde Agricultura, Editorial e Impressão até Educação, Saúde, Aviação Civil, Engenharia Civil, Ciência & Tecnologia, Justiça e E-commerce. Cada projeto é uma oportunidade para aplicar nosso conhecimento acumulado e gerar resultados significativos.',
            readmorebtn: 'Saiba mais',
            readmorebtnurl: '/quem-somos',
            supports: [
                {
                    icon: <TbRocket />,
                    title: 'Impulsione sua empresa para o futuro'
                },
                {
                    icon: <TbClick />,
                    title: 'Redefine sua interação tecnológica'
                },
                {
                    icon: <TbTargetArrow />,
                    title: 'Expertise e estratégia que revolucionam'
                }
            ],
            images: [
                QuemSomosImg1,
                LibertasFrente,
                // reuniao1,
            ],
            videobtn: 'Play',
            videoId: 't3tsMEhlvwM', // Enter YouTube video's id here
            skills: [
                // {
                //     title: 'Finance.',
                //     percent: '90'
                // },
                // {
                //     title: 'Business.',
                //     percent: '85'
                // },
                // {
                //     title: 'Investment.',
                //     percent: '80'
                // }
            ]
        },
        about2: {
            left: {
                title: 'O que fazemos',
                content1: 'A abordagem consultiva da DERRICO, é como um mergulho profundo no seu universo de negócios. Compreendemos seus desafios e metas, explorando as nuances do seu setor. O resultado? Estratégias digitais que não apenas resolvem problemas, mas também criam oportunidades de crescimento e inovação.',
                content2: 'Não somos apenas prestadores de serviços, somos seus parceiros na jornada da transformação digital. Juntos, podemos criar soluções que não apenas atendam às expectativas, mas que as superem, impulsionando seu negócio para novas alturas. Vamos construir o futuro juntos!',
                lists: [
                    { text: "Assessoria em TI", url: "/assessoria-em-ti" },
                    { text: "Software house", url: "/software-house" },
                    { text: "Observability", url: "/observability" },
                    { text: "Mentorias", url: "/mentorias" }
                ],
                // buttontext: 'Saiba mais',
                // buttonurl: '/'
            },
            right: {
                img1: OQueFazemosImg1,
                img2: OQueFazemosImg2,
                title: 'Acreditamos que o verdadeiro progresso vem da colaboração',
                content: 'Somos uma equipe especializada em assessoria em TI e desenvolvimento de soluções digitais personalizadas para plataformas web e mobile.',
                // buttontext: 'Join Now',
                // buttonurl: '/'
            }
        }
    },
    digitalsolutiondata: {
        img: DERRICODigitalSolution,
        title: 'Parceria na Criação: Conectando Inovação e Tecnologia.',
        content1: 'Nós acreditamos que o verdadeiro progresso é alcançado através da colaboração. Nossa empresa é especializada em assessoria em TI, IA e desenvolvimento de soluções digitais personalizadas para plataformas web e mobile. Com uma equipe dedicada de especialistas em tecnologia, transformamos suas ideias em realidade, assegurando que cada projeto reflita suas necessidades únicas e objetivos comerciais.',
        content2: 'Nossa abordagem consultiva começa com um entendimento profundo de seus desafios de negócios e metas. Mergulhamos nas especificidades do seu setor para oferecer estratégias digitais que não apenas resolvam problemas, mas também criem oportunidades de crescimento e inovação.',
        content3: 'Não somos apenas prestadores de serviços; somos seus parceiros no caminho para a transformação digital. Juntos, podemos criar soluções que não apenas atendam às expectativas, mas que as superem, impulsionando seu negócio para novas alturas.',
        projectfeature: {
            title: 'Assessoria em TI',
            lists: [
                'Atendimento Digital e Personalizado',
                'Análise Profunda dos Desafios de Negócios',
                'Estratégias Digitais Específicas por Setor',
                'Resolução de Problemas',
                'Inovações Estratégicas Digitais',
                'Tecnologias Mais Atuais de Mercado',
                'Desenvolvimento de Software sob Medida',
                'Experiência em Diversos Ramos de Atuação'
            ]
        },
        solutions: {
            title: 'Desenvolvimento Web & Mobile',
            content: 'Construímos softwares para a web ou dispositivos mobile, utilizando as tecnologias mais avançadas. Nosso foco está em criar experiências de usuário excepcionais que sejam intuitivas, atraentes e eficazes. De sites corporativos a aplicativos mobile complexos, nossa equipe assegura que cada solução seja segura, escalável e totalmente personalizada.',
            lists: [
                'Soluções Personalizadas',
                'Transformação Digital',
            ]
        },
        bestresult: {
            title: 'Melhores Resultados',
            img: DERRICOBestResults
        },
        relatedproject: {
            title: 'Related Projects.',
            lists: [
                {
                    img: img15,
                    title: 'Business Management',
                    path: '/portfolio-details',
                    cat: 'Business'
                },
                {
                    img: img15,
                    title: 'Digital Analysis',
                    path: '/portfolio-details',
                    cat: 'Business'
                },
                {
                    img: img15,
                    title: 'Fund Management',
                    path: '/portfolio-details',
                    cat: 'Finance'
                }
            ]
        }
    },
    portfolioassessoriadata: {
        img: AssessoriaEmTiPrincipal,
        equipe: 'Especialistas Remotos ou Presenciais',
        duracao: 'Flexível: Curto ou Longo Prazo',
        foco: 'Estratégia e Otimização de TI',
        tecnologia: 'Soluções Modernas e Escaláveis',
        title: 'Descubra a Importância da Assessoria em TI',
        content1: 'A assessoria em TI oferece soluções estratégicas por especialistas que analisam profundamente os processos e objetivos da sua empresa, recomendando as tecnologias mais adequadas para atender às suas necessidades e impulsionar resultados.',
        content2: 'A tecnologia da informação é essencial para o sucesso nos negócios modernos. Contudo, muitas empresas enfrentam dificuldades em identificar as ferramentas e estratégias ideais para otimizar seus processos e alcançar seus objetivos com eficiência.',
        content3: 'Investir em assessoria de TI não é um gasto, é uma economia inteligente. Com a ajuda certa, sua empresa resolve problemas rapidamente, otimiza processos e transforma despesas em lucro. A solução sempre compensa no final!',
        projectfeature: {
            title: 'Quem Precisa ?',
            lists: [
                'Empresas sem TI interno',
                'Organizações em transformação',
                'Negócios que precisam modernizar TI',
                'Empresas com problemas de segurança',
                'Organizações com gargalos operacionais',
                'Negócios com custos operacionais altos',
                'Empresas que querem inovar com IA',
                'Organizações planejando grandes compras',
                'Melhorar a experiência do usuário'
            ]
        },
        solutions: {
            title: 'Foco Estratégico',
            content: 'Garantimos que suas decisões de TI estejam alinhadas com os objetivos do negócio. Priorizamos soluções que maximizem o impacto nos resultados e gerem vantagem competitiva.',
            lists: [
                'Relação de Confiança',
                'Rapidez nos Resultados',
                'Renovação dos Conhecimentos',
                'Melhoria na Tomada de Decisão',
                'Economia de Recursos'
            ]
        },
        bestresult: {
            // title: 'Better Results',
            img: AssessoriaEmTiSecundaria
        },
        relatedproject: {
            title: 'Artigos Relacionados',
            lists: [
                {
                    img: Post5Sinais,
                    title: '5 Sinais de que sua Empresa Precisa de uma Assessoria em TI',
                    path: '/blog-derrico-5-sinais-de-que-sua-empresa-precisa-de-uma-assessoria-em-ti',
                    cat: 'Assessoria'
                },
                {
                    img: PostComoAssessoria,
                    title: 'Como a Assessoria em TI Reduz Custos e Aumenta a Eficiência',
                    path: '/blog-derrico-como-a-assessoria-em-ti-reduz-custos-e-aumenta-a-eficiencia',
                    cat: 'Negócios'
                },
                {
                    img: PostOPapel,
                    title: 'O Papel da Assessoria em TI na Competitividade das Empresas',
                    path: '/blog-derrico-o-papel-da-assessoria-em-ti-na-competitividade-das-empresas',
                    cat: 'Tecnologia'
                }
            ]
        }
    },
    portfoliosoftwarehousedata: {
        img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/servico-software-house-primaria.jpg',
        equipe: 'Desenvolvedores Experientes',
        duracao: 'Flexível: Curto ou Longo Prazo',
        foco: 'Soluções Customizadas',
        tecnologia: 'No-code, Low-code, High-code, Scrum',
        title: 'Transforme sua ideia em software eficiente',
        content1: 'Se você busca criar um software sob medida que acelere o crescimento do seu negócio, nós somos o parceiro certo. Na DERRICO, desenvolvemos soluções que otimizam processos, aumentam a eficiência e entregam resultados reais para empresas que querem se destacar no digital.',
        content2: 'Nosso foco está em entender as suas necessidades e oferecer uma solução flexível, escalável e totalmente personalizada. Desde o planejamento até a implementação, trabalhamos ao seu lado para garantir que o software se alinhe aos seus objetivos de negócio.',
        content3: 'Além de criar um software de alta performance, nossa equipe garante um suporte contínuo, acompanhando a evolução da sua solução e implementando melhorias conforme necessário. Com DERRICO, você não só adquire um software, mas uma parceria de longo prazo focada no seu sucesso.',
        projectfeature: {
            title: 'Quem Precisa?',
            lists: [
                'Empresas sem equipe de TI',
                'Empresas em expansão rápida',
                'Negócios que buscam automação',
                'Startups com soluções inovadoras',
                'Empresas com sistemas antigos',
                'Negócios que precisam escalar rápido',
                'Empresas que querem modernizar TI',
                'Negócios que visam maior eficiência',
                'Empresas com projetos digitais complexos'
            ]
        },
        solutions: {
            title: 'Foco Estratégico',
            content: 'Nossas soluções são pensadas para garantir que seu negócio tenha as ferramentas certas para crescer, com tecnologia avançada e inovação constante.',
            lists: [
                'Automação para maior produtividade',
                'Soluções escaláveis para crescimento',
                'Integração com sistemas existentes',
                'Desenvolvimento web e mobile ágil',
                'Customização total para seu negócio',
                'Inovação contínua para acompanhar o mercado'
            ]
        },
        bestresult: {
            // title: 'Better Results',
            img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/servico-software-house-secundaria.jpg'
        },
        relatedproject: {
            title: 'Artigos Relacionados',
            lists: [
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/5-tendencias-que-estao-revolucionando-as-software-houses-em-2024-primaria.png',
                    title: '5 Tendências que Estão Revolucionando as Software Houses em 2024',
                    path: '/blog-derrico-5-tendencias-que-estao-revolucionando-as-software-houses-em-2024',
                    cat: 'Tecnologia'
                },
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/o-poder-das-software-houses-impulsionando-a-inovacao-digital-primaria.png',
                    title: 'O Poder das Software Houses: Impulsionando a Inovação Digital',
                    path: '/blog-derrico-o-poder-das-software-houses-impulsionando-a-inovacao-digital',
                    cat: 'Tecnologia'
                },
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/software-house-vs-desenvolvimento-interno-qual-a-melhor-escolha-primaria.png',
                    title: 'Software House vs. Desenvolvimento Interno: Qual a Melhor Escolha?\n',
                    path: '/blog-derrico-software-house-vs-desenvolvimento-interno-qual-a-melhor-escolha',
                    cat: 'Finance'
                }
            ]
        }
    },
    portfolioobservabilitydata: {
        img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/servico-observability-primaria.jpg',
        equipe: 'Especialistas em Monitoramento',
        duracao: 'Flexível: Curto ou Longo Prazo',
        foco: 'Monitoramento de Sistemas',
        tecnologia: 'Monitoramento, Automação',
        title: 'Monitore seus sistemas e ganhe eficiência',
        content1: 'Ter visibilidade completa do seu sistema é essencial para identificar problemas antes que eles causem prejuízos. Com nossa solução de Observability, você acompanha o desempenho em tempo real, detecta falhas rapidamente e otimiza a operação para que sua empresa funcione com máxima eficiência.',
        content2: 'Acompanhe métricas, logs e rastreamentos de uma forma integrada, simplificando a identificação de gargalos e reduzindo o tempo de inatividade. Nossa solução permite que você tenha um controle completo da saúde do sistema, garantindo que ele esteja sempre no seu melhor desempenho.',
        content3: 'Além disso, nossa equipe oferece suporte contínuo, ajudando a interpretar dados e implementar melhorias. Com a DERRICO, você conta com um monitoramento que vai além do básico, proporcionando insights que realmente fazem a diferença no seu dia a dia.',
        projectfeature: {
            title: 'Quem Precisa?',
            lists: [
                'Empresas com sistemas críticos',
                'Negócios que buscam alta disponibilidade',
                'Equipes de TI que querem proatividade',
                'Empresas com alto volume de dados',
                'Negócios que querem evitar downtime',
                'Empresas que precisam de automação',
                'Organizações que prezam por segurança',
                'Negócios em crescimento constante',
                'Empresas que buscam otimização contínua'
            ]
        },
        solutions: {
            title: 'Foco Estratégico',
            content: 'Nosso foco está em entregar visibilidade total dos seus sistemas, garantindo alta performance e agilidade.',
            lists: [
                'Monitoramento em tempo real',
                'Automação de detecção de falhas',
                'Análise de desempenho contínua',
                'Redução de tempo de inatividade',
                'Relatórios e insights proativos',
                'Otimização de custos operacionais'
            ]
        },
        bestresult: {
            // title: 'Better Results',
            img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/servico-observability-secundaria.jpg'
        },
        relatedproject: {
            title: 'Artigos Relacionados',
            lists: [
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/a-revolucao-da-observability-seu-negocio-esta-preparado-primaria.png',
                    title: 'A Revolução da Observability: Seu Negócio Está Preparado?',
                    path: '/blog-derrico-a-revolucao-da-observability-seu-negocio-esta-preparado',
                    cat: 'Inteligência Artificial'
                },
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/observability-o-segredo-das-empresas-que-nunca-dormem-primaria.png',
                    title: 'Observability: O Segredo das Empresas que Nunca Dormem',
                    path: '/blog-derrico-observability-o-segredo-das-empresas-que-nunca-dormem',
                    cat: 'Negócios'
                },
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/o-novo-diferencial-competitivo-para-seu-negocio-primaria.png',
                    title: 'O Novo Diferencial Competitivo para Seu Negócio',
                    path: '/blog-derrico-o-novo-diferencial-competitivo-para-seu-negocio',
                    cat: 'Tecnologia'
                }
            ]
        }
    },
    portfoliomentoriasdata: {
        img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/servico-mentorias-primaria.jpg',
        equipe: 'Mentores Especializados',
        duracao: 'Flexível: Curto ou Longo Prazo',
        foco: 'Desenvolvimento Profissional',
        tecnologia: 'Treinamentos Práticos',
        title: 'Mentorias para Crescimento e Sucesso',
        content1: 'Se você quer acelerar o desenvolvimento da sua equipe ou sua empresa, nossas mentorias são o caminho certo. Oferecemos suporte estratégico e prático para empresas que querem otimizar processos, alinhar equipes e crescer com segurança. Nossa equipe de mentores traz conhecimento de mercado e experiência prática para garantir que você alcance resultados reais.',
        content2: 'Com uma abordagem personalizada, ajudamos a identificar as lacunas de habilidades e implementamos planos de ação claros para superá-las. Não importa se seu foco é tecnologia, gestão ou inovação, nossas mentorias são projetadas para gerar impacto imediato.',
        content3: 'A DERRICO oferece uma parceria de longo prazo, ajustando o foco da mentoria conforme seu negócio evolui. Seja para capacitar sua equipe ou para transformar a cultura da empresa, nós ajudamos você a liderar o futuro com segurança e conhecimento.',
        projectfeature: {
            title: 'Quem Precisa?',
            lists: [
                'Empresas em fase de expansão',
                'Startups buscando escalabilidade',
                'Equipes de TI em transformação',
                'Líderes que querem crescer rápido',
                'Negócios com foco em inovação',
                'Empresas com gaps de habilidades',
                'Equipes que precisam de alinhamento',
                'Organizações em processos de mudança',
                'Negócios que visam otimização contínua'
            ]
        },
        solutions: {
            title: 'Foco Estratégico',
            content: 'Nosso foco está em oferecer mentorias que entreguem resultados práticos e de impacto direto no crescimento do seu negócio.',
            lists: [
                'Desenvolvimento de líderes',
                'Otimização de processos internos',
                'Capacitação técnica em TI',
                'Aumento da produtividade',
                'Transformação digital de equipes',
                'Alinhamento estratégico empresarial'
            ]
        },
        bestresult: {
            // title: 'Better Results',
            img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/servico-mentorias-secundaria.jpg'
        },
        relatedproject: {
            title: 'Artigos Relacionados',
            lists: [
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/mentorias-o-caminho-para-o-sucesso-profissional-em-ti-e-ia-primaria.jpg',
                    title: 'O Caminho para o Sucesso Profissional em TI & IA',
                    path: '/blog-derrico-mentorias-o-caminho-para-o-sucesso-profissional-em-ti-e-ia',
                    cat: 'Educação'
                },
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/transformando-desafios-em-oportunidades-o-poder-da-ia-na-sua-empresa-primaria.jpg',
                    title: 'Transformando Desafios em Oportunidades: O Poder da IA na Sua Empresa',
                    path: '/blog-derrico-transformando-desafios-em-oportunidades-o-poder-da-ia-na-sua-empresa',
                    cat: 'Inteligência Artificial'
                },
                {
                    img: 'https://sos-nyc3-prd-001.nyc3.cdn.digitaloceanspaces.com/static/images/ciberseguranca-protegendo-seu-negocio-na-era-digital-primaria.jpg',
                    title: 'Cibersegurança: Protegendo seu Negócio na Era Digital',
                    path: '/blog-derrico-ciberseguranca-protegendo-seu-negocio-na-era-digital',
                    cat: 'Negócios'
                }
            ]
        }
    },
    Tabdata: {
        tab1: {
            secTitle: 'Porque escolher a DERRICO',
            secSubtitle: 'Nossos Melhores Recursos',
            tabsnav: [
                {
                    title: "Experiência Comprovada"
                },
                {
                    title: "Atendemos Online"
                },
                {
                    title: "Inovação em Tecnologias de Ponta"
                },
                {
                    title: "Soluções sob Medida"
                }
            ],
            items: [
                {
                    img: DERRICOProofExpertise,
                    desc: "Com mais de trinta anos de experiência acumulada em tecnologia, nossa empresa, embora jovem com apenas três anos de fundação, se destaca no cenário tecnológico. A profunda expertise do nosso time em uma ampla gama de projetos de Tecnologia da Informação nos permite oferecer soluções robustas e eficazes. Essas soluções são meticulosamente ajustadas para corresponder às dinâmicas do mercado e satisfazer as demandas específicas de cada cliente, assegurando assim resultados excepcionais e adequação perfeita às necessidades de nossos parceiros. ",
                    // button: "Get Started",
                    // buttonurl: '#',
                },
                {
                    img: DERRICOOnlineAttending,
                    desc: "Oferecemos uma gama completa de serviços online, projetados para garantir conveniência e eficiência máxima. Utilizando tecnologias e plataformas avançadas, nosso atendimento online é especialmente configurado para resolver questões complexas com a mesma eficácia que se esperaria de um suporte presencial. Este nível de competência online assegura a satisfação completa e o sucesso contínuo dos nossos clientes. Nossa abordagem inovadora ao suporte online nos permite lidar efetivamente com uma ampla variedade de desafios, garantindo que todas as necessidades dos clientes sejam atendidas com rapidez e precisão.",
                    // button: "Get Started",
                    // img_position: "img_right",
                    // buttonurl: '#',
                },
                {
                    img: DERRICOInovation,
                    desc: "Estamos constantemente na vanguarda do mercado, integrando as mais recentes inovações tecnológicas aos nossos serviços. Essa postura proativa nos permite oferecer soluções de ponta em software e hardware, maximizando a performance e a segurança dos projetos de nossos clientes. Nossa dedicação em manter-nos atualizados com o progresso tecnológico assegura que estamos sempre preparados para enfrentar novos desafios e aproveitar oportunidades emergentes. Ao adotar as inovações mais recentes, reforçamos nosso compromisso em promover a transformação digital eficaz e segura para todos os nossos clientes, posicionando-os para sucesso a longo prazo no ambiente competitivo atual.",
                    // button: "Get Started",
                    // buttonurl: '#',
                },
                {
                    img: DERRICOCustomizedSolutions,
                    desc: "Cada cliente é único e suas necessidades são específicas. Por isso, dedicamo-nos a oferecer atendimento personalizado, desenvolvendo soluções que se ajustam perfeitamente aos objetivos e requisitos de cada projeto. Nosso compromisso é com a entrega de resultados que não apenas atendam, mas superem as expectativas. Através de uma abordagem colaborativa e atenta aos detalhes, garantimos que cada estratégia seja implementada com precisão para maximizar o sucesso. Esta atenção ao cliente nos permite criar parcerias duradouras, fundamentadas na confiança e na eficácia contínua.",
                    // button: "Get Started",
                    // buttonurl: '#',
                }
            ]
        },
        tab2: {
            secTitle: 'Why People Choose Us.',
            secSubtitle: 'Our Best Features.',
            tabnav: [
                {
                    title: 'Wide Range Of Business Services',
                    content: 'The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top'
                },
                {
                    title: 'Our Best Features For You',
                    content: 'The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top'
                },
                {
                    title: 'Skills To Fulfill Your Jobs',
                    content: 'The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top'
                },
                {
                    title: 'Our Expert Team',
                    content: 'The Argument In Favorite Of Using Filter To Text Goes Some Thing Like This Top'
                }
            ],
            tablists: [
                {
                    title: 'We Can Give the Best Facilities for Business',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.',
                    lists: [
                        {
                            icon: <FiLock />,
                            title: 'Top Security',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <FiBarChart />,
                            title: 'Revenue Growth',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <FiUserCheck />,
                            title: 'Risk Manage',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <GiCutDiamond />,
                            title: 'Fast Support',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        }
                    ]
                },
                {
                    title: 'We Can Give the Best Facilities for Business',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.',
                    lists: [
                        {
                            icon: <FiBarChart />,
                            title: 'Marketing Strategy',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <IoIosHammer />,
                            title: 'Best Solutions',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <AiOutlineRocket />,
                            title: 'Speed Optimization',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <GiCutDiamond />,
                            title: 'Quick Support',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        }
                    ]
                },
                {
                    title: 'We Can Give the Best Facilities for Business',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.',
                    lists: [
                        {
                            icon: <FiLock />,
                            title: 'Top Security',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <FiBarChart />,
                            title: 'Revenue Growth',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <FiUserCheck />,
                            title: 'Risk Manage',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <GiCutDiamond />,
                            title: 'Fast Support',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        }
                    ]
                },
                {
                    title: 'We Can Give the Best Facilities for Business',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad dolore eius harum incidunt iste iusto odit quibusdam quis reiciendis vero.',
                    lists: [
                        {
                            icon: <FiBarChart />,
                            title: 'Marketing Strategy',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <IoIosHammer />,
                            title: 'Best Solutions',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <AiOutlineRocket />,
                            title: 'Speed Optimization',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        },
                        {
                            icon: <GiCutDiamond />,
                            title: 'Quick Support',
                            content: 'The argument in favor of using filler text goes some thing like this'
                        }
                    ]
                }
            ]
        },
        tab3: {
            tabnav: [
                'Audit',
                'Strategy',
                'Sustainability'
            ],
            tablists: [
                {
                    content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet'
                },
                {
                    content: 'All the Lorem Ipsum generators on the Internet. There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text.'
                },
                {
                    content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn\'t anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet'
                }
            ]
        }
    },
    CounterUp: {
        secTitle: 'Números Dizem',
        secSubtitle: 'Veja o que nossos',
        items: [
            {
                icon: <AiOutlineCheckCircle className="icon" />,
                number: <CountUp start={0}
                                 end={3}
                                 duration={8}
                                 separator=" "
                                 decimal=","
                                 prefix=" "
                                 suffix=" "
                />,
                numsuffix: '+',
                title: 'Anos no Mercado'
            },
            {
                icon: <FaHandshake className="icon" />,
                number: <CountUp start={0}
                                 end={20}
                                 duration={8}
                                 separator=" "
                                 decimal=","
                                 prefix=" "
                                 suffix=" "
                />,
                numsuffix: '+',
                title: 'Clientes Satisfeitos'
            },
            {
                icon: <FiGlobe className="icon" />,
                number: <CountUp start={0}
                                 end={2}
                                 duration={8}
                                 separator=" "
                                 decimal=","
                                 prefix=" "
                                 suffix=" "
                />,
                numsuffix: '+',
                title: 'Países'
            },
            {
                icon: <AiOutlineNotification className="icon" />,
                number: <CountUp start={0}
                                 end={30}
                                 duration={8}
                                 separator=" "
                                 decimal=","
                                 prefix=" "
                                 suffix=" "
                />,
                numsuffix: '+',
                title: 'Projetos Concluídos'
            },

        ],
        // counterbottomtitle: 'Do not hesitate to contact us for instant help and support',
        // counterbottombtn: 'Let\'s Start Now',
        // counterbottombtnurl: '/',
    },
    shopdata: {
        category_collection: {
            secTitle: 'Check Out Our Newest items.',
            secSubtitle: 'Newest Collection For You',
            left: {
                title: 'Product Collection',
                button: 'Shop Now',
                img: category1,
                cardlink: '/shop'
            },
            right: {
                title: 'Basket Collection',
                button: 'Shop Now',
                img: category1,
                cardlink: '/shop'
            }
        },
        sidebar: {
            categories: {
                title: 'Categories',
                lists: [
                    {
                        title: 'Accents',
                        number: '(11)',
                        path: '/product-details'
                    },
                    {
                        title: 'Accessories',
                        number: '(22)',
                        path: '/product-details'
                    },
                    {
                        title: 'Bed & Bath',
                        number: '(23)',
                        path: '/product-details'
                    },
                    {
                        title: 'Chair',
                        number: '(7)',
                        path: '/product-details'
                    },
                    {
                        title: 'Clocks',
                        number: '(3)',
                        path: '/product-details'
                    },
                    {
                        title: 'Dining',
                        number: '(11)',
                        path: '/product-details'
                    },
                    {
                        title: 'Furniture',
                        number: '(5)',
                        path: '/product-details'
                    },
                    {
                        title: 'Gifts',
                        number: '(31)',
                        path: '/product-details'
                    },
                    {
                        title: 'Lighting',
                        number: '(22)',
                        path: '/product-details'
                    },
                    {
                        title: 'Smart TVs',
                        number: '(13)',
                        path: '/product-details'
                    },
                    {
                        title: 'Table',
                        number: '(3)',
                        path: '/product-details'
                    },
                ]
            },
            productcolor: {
                title: 'Product Color.',
                lists: [
                    'black',
                    'orange',
                    'blue',
                    'darkgray'
                ]
            },
            populartags: {
                title: 'Popular Tags',
                lists: [
                    {
                        title: 'Armchairs',
                        path: '/product-details'
                    },
                    {
                        title: 'Sofas',
                        path: '/product-details'
                    },
                    {
                        title: 'Wood',
                        path: '/product-details'
                    },
                    {
                        title: 'Office',
                        path: '/product-details'
                    },
                    {
                        title: 'Desks',
                        path: '/product-details'
                    },
                    {
                        title: 'Stools',
                        path: '/product-details'
                    },
                    {
                        title: 'Footstools',
                        path: '/product-details'
                    },
                    {
                        title: 'Outdoor',
                        path: '/product-details'
                    },
                    {
                        title: 'Bedroom',
                        path: '/product-details'
                    }
                ]
            }
        }
    },
    Testimonialdata: {
        secTitle: 'Say About Us.',
        secSubtitle: 'See What Our Customers',
        imagesshape: [
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            },
            {
                img: img3
            }
        ]
    },
    Teammemberdata: {
        secTitle: 'Expert Team Members.',
        secSubtitle: 'Meet Our Dedicated',
        teammembers: [
            {
                name: 'Tanbir Ahmed',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Adam Smith',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Pam Sharon',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            }
        ],
        moreTeam: [
            {
                name: 'Tanbir Ahmed',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Adam Smith',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Pam Sharon',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Domien Doe',
                designation: 'Junior Designer',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Pam Sharon',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            },
            {
                name: 'Richard Pam',
                designation: 'Director',
                img: img4,
                socials: [
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com'
                    },
                    {
                        icon: <GoX />,
                        url: 'https://twitter.com'
                    },
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://linkedin.com'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://youtube.com'
                    }
                ],
                buttontext: 'Read His Story',
                buttonurl: '/team-details'
            }
        ],
        teamDetails: {
            img: Founder,
            name: 'Geison Derrico',
            designation: 'Fundador & CEO',
            address: 'Matriz, Brasília, Brasil',
            phonenumber: '+55 61 98185-9230',
            email: 'geison@derrico.tec.br',
            website: 'www.linkedin.com/in/geisonderrico/',
            expertise: {
                title: 'Áreas de Experiência',
                lists: [
                    "Estratégica Comercial",
                    "Gerenciamento de Operações",
                    "Desenvolvimento Tecnológico",
                    "Gestão de Projetos"
                ]
            },
            educations: {
                title: 'Educação',
                lists: [
                    "CPRE, Certificado em Engenharia de Requisitos",
                    "SFPC, Certificado em SCRUM",
                    "Graduação, Universidade Católica de Brasília",
                    "Técnico, NetWork Colégio Técnico de PD",
                ]
            },
            bottomContent: {
                title: 'Uma Breve Descrição Sobre Mim',
                content1: 'Sou analista de sistemas formado pela Universidade Católica de Brasília, apaixonado por tecnologia e movido pelos desafios que ela apresenta. Minha trajetória é marcada pela adaptação às transformações tecnológicas desde 1988, acompanhando e contribuindo para mudanças significativas que moldaram o desenvolvimento e a aplicação de soluções digitais. Enfrentei desafios em setores diversos, como saúde, educação, ciência e agricultura, liderando projetos que conectaram inovações tecnológicas a demandas reais. Essas experiências me permitiram criar soluções que otimizam processos, aumentam a eficiência e promovem a inovação em diferentes cenários.',
                content2: 'Com uma abordagem que combina visão estratégica e conhecimento técnico, prezo por parcerias sólidas, transparência e resultados tangíveis. Meu trabalho é impulsionado pelo compromisso de criar ferramentas que resolvem problemas e oferecem novas oportunidades, sempre alinhadas às demandas de um mercado em constante evolução.',
                blockquote: {
                    content: 'A inovação é o que distingue um líder de um seguidor.',
                    name: 'Steve Jobs,',
                    designation: 'Co-fundador da Apple e líder revolucionário em tecnologia.'
                }
            },
            sidebar: {
                needhelp: {
                    title: 'Need Any Help?',
                    content: 'Contact us at the Consulting office nearest to you or submit a business inquiry online',
                    button: 'Contact Us',
                    buttonurl: '/contato'
                },
                companybtn: 'Apresentação da DERRICO',
                companybtnurl: '/presentations/DERRICOFullPresentationPTR4.pdf', // Enter download file url here
            }
        }
    },
    faqdata: [
        {
            title: 'How do I find my Windows product key?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'Do you have any built-in caching?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'Can i add/upgrade my plan at any time?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'What access comes with my hosting plan?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'How do i change my password?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'What\'s included?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'What services do you provide?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        },
        {
            title: 'How much will it cost?',
            content: 'Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus. Sequitur mutatin onem consuetudium.'
        }
    ],
    ctadata: {
        cta1: {
            title: 'Subscribe Newsletter to Get Latest Discount.',
            content: 'Be the first to hear about our latest products & offers. As a welcome gift, we’ll email you a 20% OFF discount code.'
        }
    },
    Chartareadata: {
        secTitle: 'Better Results.',
        secSubtitle: 'Our Growth',
        title1: 'Increase Sales',
        content1: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem eaque ipsa quae ab illo inventore sit amet, consectetur adipisicing elit. Sit, vitae.',
        title2: 'Real-Time Analytics',
        content2: 'Nullam sollicitudin nisl at nunc tempor pulvindolor sit amet, adipiscinar. Quisque gravida, tellus egett vehicula fel.',
        buttontext: 'Learn More',
        buttonurl: '/about'
    },
    Pricingdata: {
        secTitle: 'Choose Your Plan.',
        secSubtitle: 'Affordable Packages',
        tablist: [
            "Monthly",
            "Yearly"
        ],
        planlists: [
            {
                plans: [
                    {
                        title: 'Basic',
                        price: '19',
                        currency: '$',
                        mo: '/Monthly',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: false
                    },
                    {
                        title: 'Popular',
                        price: '39',
                        currency: '$',
                        mo: '/Monthly',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: true
                    },
                    {
                        title: 'Premium',
                        price: '78',
                        currency: '$',
                        mo: '/Monthly',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: false
                    }
                ]
            },
            {
                plans: [
                    {
                        title: 'Basic',
                        price: '200',
                        currency: '$',
                        mo: '/Year(save 20%)',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: false
                    },
                    {
                        title: 'Popular',
                        price: '460',
                        currency: '$',
                        mo: '/Year(save 20%)',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: true
                    },
                    {
                        title: 'Premium',
                        price: '300',
                        currency: '$',
                        mo: '/Year(save 20%)',
                        features: [
                            'Limitless concepts',
                            'Community access',
                            'Expert Reviews',
                            'Creative Layouts',
                            'Free Support',
                            'Free Fonts'
                        ],
                        buttontext: 'Get Started',
                        buttonurl: '/',
                        isactive: false
                    }
                ]
            }
        ]
    },
    errorpagedata: {
        img: errorimg,
        title: 'Opps! Página não encontrada.',
        content: 'A página que você está procurando pode ter sido removida, teve seu nome alterado ou está temporariamente indisponível.',
        connector: 'ou',
        backbtn: 'Voltar para Home',
        footermenu: [
            {
                title: 'Home',
                path: '/'
            },
            {
                title: 'About Us',
                path: '/about'
            },
            {
                title: 'Service',
                path: '/services'
            },
            {
                title: 'Contact',
                path: '/contato'
            },
        ]
    },
    contactdata: {
        secTitle: 'Quer contratar nossas soluções?',
        // secSubtitle: 'Entre em contato conosco',
        content: 'Entre em contato conosco hoje mesmo e descubra como nossas soluções em tecnologia da informação e inteligência artificial podem transformar seu negócio. Estamos prontos para ouvir você e ajudar a alcançar seus objetivos com tecnologia de ponta e atendimento personalizado.',
        socials: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/company/derrico'
            },
            {
                icon: <FaInstagram />,
                url: 'https://www.instagram.com/derrico.solucoes'
            },
            {
                icon: <FaFacebookF />,
                url: 'https://facebook.com/derrico.solucoes'
            },
            {
                icon: <FaYoutube />,
                url: 'https://www.youtube.com/@derrico.solucoes'
            }
        ],
        sidebar: {
            title: 'Nosso Escritório',
            img: LibertasFrente,
            content: 'DERRICO, sua empresa parceira em Soluções de Tecnologia da Informação & Inteligência Artificial',
            address: 'SAUS Quadra 01, Bloco M, Sala 907, Edifício Libertas, Brasília/DF, CEP 70.070-935',
            phonenum: '+55 61 3046-0200',
            // phonenum2: '+55 61 98185-9230',
            email: 'contato@derrico.tec.br',
            // email2: 'inquiry@minzel.com',
            // button: 'Contatar'
        }
    },
    Footerdata: {
        ftLeftwidget: {
            footerlogo: DERRICOLogoBranco,
            content: 'DERRICO, sua empresa parceira em Soluções de Tecnologia da Informação & Inteligência Artificial',
            phonenumber: '+55 61 3046-0200',
            email: 'contato@derrico.tec.br',
            address: 'Brasília DF, Brasil',
            socials: {
                title: 'Siga & Conecte-se.',
                lists: [
                    {
                        icon: <FaLinkedinIn />,
                        url: 'https://www.linkedin.com/company/derrico'
                    },
                    {
                        icon: <FaInstagram />,
                        url: 'https://www.instagram.com/derrico.solucoes'
                    },
                    {
                        icon: <FaFacebookF />,
                        url: 'https://facebook.com/derrico.solucoes'
                    },
                    {
                        icon: <FaYoutube />,
                        url: 'https://www.youtube.com/@derrico.solucoes'
                    }
                ]
            }
        },
        ftCommonLinks: {
            title: 'Acesso Rápido',
            lists: [
                {
                    title: 'Quem somos',
                    url: '/quem-somos'
                },
                {
                    title: '• Assessoria em TI',
                    url: '/assessoria-em-ti'
                },
                {
                    title: '• Software House',
                    url: '/software-house'
                },
                {
                    title: '• Observability',
                    url: '/observability'
                },
                {
                    title: '• Mentorias',
                    url: '/mentorias'
                },
                {
                    title: 'Parceiros',
                    url: '/parceiros-derrico'
                },
                {
                    title: '📣 Blog',
                    url: '/blog-derrico'
                },
                {
                    title: 'Contato',
                    url: '/contato'
                },
                {
                    title: '',
                    url: '#'
                },
                {
                    title: 'Política da Empresa',
                    url: '/politica-da-empresa'
                },
                {
                    title: 'Política de Privacidade',
                    url: '/politica-de-privacidade'
                },
            ]
        },
        ftSchedule: {
            title: 'Horário de Trabalho',
            offday: 'Sáb-Dom:',
            litss: [
                {
                    day: 'Segunda:',
                    time: '09:00-18:00'
                },
                {
                    day: 'Terça:',
                    time: '09:00-18:00'
                },
                {
                    day: 'Quarta:',
                    time: '09:00-18:00'
                },
                {
                    day: 'Quinta:',
                    time: '09:00-18:00'
                },
                {
                    day: 'Sexta:',
                    time: '09:00-18:00'
                }
            ]
        }
    },
    companyPrivacydata: [
        {
            title: 'Introdução',
            content: 'Bem-vindo à Política da Empresa DERRICO Soluções em TI & IA Ltda., sua parceira confiável em Soluções de Tecnologia da Informação & Inteligência Artificial. Nosso objetivo é garantir excelência e segurança em todos os serviços oferecidos aos nossos clientes. Estas políticas aplicam-se a todos os nossos modos de operação, tanto em interações online quanto presenciais.'
        },
        {
            title: 'Código de Conduta Profissional',
            content: 'Esperamos que nossa equipe mantenha os mais altos padrões de ética profissional, com compromisso com a integridade, confidencialidade e um ambiente de trabalho respeitoso. É vital que todos os colaboradores sigam estas diretrizes para manter a confiança de nossos clientes e parceiros.'
        },
        {
            title: 'Confidencialidade e Segurança da Informação',
            content: 'A DERRICO Soluções em TI & IA Ltda. compromete-se a proteger as informações confidenciais de nossos clientes. Utilizamos tecnologias avançadas de segurança e práticas recomendadas para prevenir o acesso não autorizado aos dados. Todos os funcionários são obrigados a seguir nossa política de segurança rigorosa.'
        },
        {
            title: 'Atendimento ao Cliente',
            content: 'Para agendar um atendimento online ou presencial, os clientes devem contatar nossa equipe pelo +55 61 3046-0200 ou contato@derrico.tec.br. Nos comprometemos a responder todas as solicitações dentro de 24 horas, garantindo eficiência e qualidade no suporte.'
        },
        {
            title: 'Política de Privacidade',
            content: 'Nossa política de privacidade, disponível em https://derrico.tec.br/politica-de-privacidade, detalha como coletamos e usamos as informações dos clientes, em conformidade com as leis de proteção de dados aplicáveis.'
        },
        {
            title: 'Conformidade com Normas e Legislações',
            content: 'A DERRICO Soluções em TI & IA Ltda. segue rigorosamente as leis e regulamentos do setor de TI. Nossa equipe é treinada regularmente para garantir conformidade e atualização constante.'
        },
        {
            title: 'Desenvolvimento e Entrega de Projetos',
            content: 'Nosso processo de desenvolvimento inclui várias fases, garantindo que todos os projetos sejam entregues com a mais alta qualidade. Os clientes recebem atualizações regulares durante cada fase do projeto.'
        },
        {
            title: 'Feedback e Reclamações',
            content: 'Encorajamos o feedback para melhorar continuamente nossos serviços. Reclamações podem ser enviadas diretamente para nosso canal de suporte ao cliente em administrativo@derrico.tec.br, onde serão prontamente investigadas e resolvidas.'
        },
        {
            title: 'Modificações nas Políticas',
            content: 'A DERRICO Soluções em TI & IA Ltda. reserva-se o direito de alterar estas políticas quando necessário. Todas as alterações serão comunicadas através de nosso site oficial e e-mails diretos aos clientes.'
        },
        {
            title: 'Contato para Dúvidas',
            content: 'Para mais informações ou dúvidas sobre nossas políticas, entre em contato conosco via: Telefone: +55 61 3046-0200, e-mail: contato@derrico.tec.br ou endereço para correspondência: SAUS Quadra 01, Bloco M, Sala 907, Edifício Libertas, Brasília/DF, CEP 70.070-935.'
        }
    ],
    privacypolicydata: [
        {
            title: 'Última Atualização',
            content: '02 de maio de 2024.'
        },
        {
            title: 'Introdução',
            content: 'A Empresa DERRICO Soluções em TI & IA Ltda. respeita a privacidade de seus clientes e está comprometida em proteger as informações pessoais que são compartilhadas conosco. Esta política de privacidade explica como coletamos, usamos, divulgamos, armazenamos e protegemos suas informações.'
        },
        {
            title: 'Coleta de Informações',
            content: 'Nós coletamos informações pessoais que você nos fornece voluntariamente ao se registrar em nossos serviços, ao preencher formulários em nosso site https://derrico.tec.br, ou durante consultas presenciais. As informações coletadas podem incluir, mas não se limitam a, seu nome, endereço de e-mail, número de telefone e detalhes do projeto.'
        },
        {
            title: 'Uso de Informações',
            content: 'Utilizamos suas informações para: 1. Fornecer, operar e manter nossos serviços; 2. Melhorar, personalizar e expandir nossos serviços; 3. Entender e analisar como você usa nossos serviços; 4. Desenvolver novos produtos, serviços, características e funcionalidades; 5. Comunicar-se com você, diretamente ou através de um de nossos parceiros, incluindo para atendimento ao cliente, para fornecer atualizações e outras informações relacionadas ao serviço, e para fins de marketing e promoção; 6. Enviar-lhe e-mails.'
        },
        {
            title: 'Compartilhamento de Informações',
            content: 'Podemos compartilhar suas informações pessoais com prestadores de serviços terceirizados que nos ajudam a operar nosso site, conduzir nossos negócios, ou servir nossos usuários, desde que essas partes concordem em manter esta informação confidencial. Também podemos compartilhar suas informações quando acreditamos que a liberação é apropriada para cumprir a lei, fazer cumprir nossas políticas de site ou proteger nossos ou outros direitos, propriedade ou segurança.'
        },
        {
            title: 'Segurança da Informação',
            content: 'A segurança de suas informações pessoais é importante para nós. Implementamos medidas de segurança adequadas para proteger suas informações de acessos não autorizados, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela internet ou método de armazenamento eletrônico é 100% seguro e não podemos garantir sua segurança absoluta.'
        },
        {
            title: 'Acesso e Controle de suas Informações',
            content: 'Você tem direito a acessar, corrigir, alterar ou solicitar a exclusão de suas informações pessoais que mantemos. Se desejar exercer algum desses direitos, entre em contato conosco através do e-mail administrativo@derrico.tec.br ou pelo telefone +55 61 3046-0200.'
        },
        {
            title: 'Mudanças na Política de Privacidade',
            content: 'Reservamo-nos o direito de alterar esta política de privacidade a qualquer momento, portanto, revise-a frequentemente. Alterações e esclarecimentos entrarão em vigor imediatamente após sua publicação em nosso site. Se fizermos alterações materiais a esta política, notificaremos você aqui que ela foi atualizada, para que você tenha conhecimento das informações que coletamos, como as usamos e sob que circunstâncias, se houver, as usamos e/ou divulgamos.'
        },
        {
            title: 'Contato',
            content: 'Se você tiver quaisquer perguntas sobre esta política de privacidade, entre em contato conosco através do e-mail administrativo@derrico.tec.br ou pelo telefone +55 61 3046-0200.'
        }
    ],
}
export default sectiondata

