import React from 'react'
import { Link } from "react-router-dom";
import { FiPlus, FiChevronRight } from 'react-icons/fi'

export default function PartnerFeatures({ partner }) {
    const { id, path, title, author_url, date, author, images, description } = partner;

    return (
        <div className="blog">
            <div className="blog-img-box">
                <img src={images[0]} alt="Partner" />
                <div className="blog-overlay">
                    <Link to={`${path}`}><FiPlus className="icon" /></Link>
                </div>
            </div>
            <div className="blog-content-box">
                <Link to={`${path}`}>
                    <h2 className="blog-title">
                        {title}
                    </h2>
                </Link>
                <p className="blog-desc">
                    {description}
                </p>
                <div className="blog-footer">
                    <Link className="theme-button" to={`${path}`}>"Leia mais" <FiChevronRight className="icon" /></Link>
                </div>
            </div>
        </div>
    )
}
