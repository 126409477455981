import React, { Component } from 'react';

const PartnerContext = React.createContext();

export default class PartnersProvider extends Component {
    state = {
        partners: [],
        loading: true,  // State to indicate loading status
    };

    componentDidMount() {
        this.getData();
    }

    getData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DERRICO_API}/partners/pt/`); // Update this URL as needed
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const partners = this.formatData(data); // Assuming 'data' has the structure you expect
            this.setState({
                partners,
                loading: false,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ loading: false });
        }
    };

    formatData(items) {
        return items.map(item => {
            let id = item.id;
            let images = item.image_sections.map(image=> image.image);

            return {...item, images, id};
        });
    }

    render() {
        return (
            <PartnerContext.Provider
                value={{
                    ...this.state,
                }}
            >
                {this.props.children}
            </PartnerContext.Provider>
        );
    }
}

const PartnersConsumer = PartnerContext.Consumer;

export function withPartnerConsumer(Component) {
    return function ConsumerWrapper(props) {
        return (
            <PartnersConsumer>
                {value => <Component {...props} context={value} />}
            </PartnersConsumer>
        );
    };
}

export { PartnersProvider, PartnersConsumer, PartnerContext };
