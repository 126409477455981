import React from "react";
import { Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import About_Page from './pages/About_Page'
import BlogGridPage from './pages/BlogGridPage'
import BlogDetailsPage from './pages/BlogDetailsPage'
import ContactPage from './pages/ContactPage'
import Error from './pages/Error'
import CompanyPolicy from "./pages/CompanyPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PortfolioAssessoriaDetailsPage from "./pages/PortfolioAssessoriaDetailsPage";
import PortfolioSoftwareHouseDetailsPage from "./pages/PortfolioSoftwareHouseDetailsPage";
import PortfolioObservabilityDetailsPage from "./pages/PortfolioObservabilityDetailsPage";
import PortfolioMentoriasDetailsPage from "./pages/PortfolioMentoriasDetailsPage";
import LinguagensPage from "./pages/LinguagesPage";
import ServicosNuvemPage from "./pages/ServicosNuvemPage";
import LojasAplicativosPage from "./pages/LojasAplicativosPage";
import SolucoesIntegracaoPage from "./pages/SolucoesIntegracaoPage";
import MeiosPagamentoPage from "./pages/MeiosPagamentoPage";
import GerenciamentoProjetosPage from "./pages/GerenciamentoProjetosPage";
import ParceirosGridPage from "./pages/ParceirosGridPage";
import ParceirosDetailsPage from "./pages/ParceirosDetailsPage";

import "./assets/css/style.css";
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <Route>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/quem-somos" component={About_Page} />
          <Route path="/assessoria-em-ti" component={PortfolioAssessoriaDetailsPage} />
          <Route path="/software-house" component={PortfolioSoftwareHouseDetailsPage} />
          <Route path="/observability" component={PortfolioObservabilityDetailsPage} />
          <Route path="/mentorias" component={PortfolioMentoriasDetailsPage} />
          <Route path="/linguagens" component={LinguagensPage} />
          <Route path="/servicos-de-nuvem" component={ServicosNuvemPage} />
          <Route path="/lojas-de-aplicativos" component={LojasAplicativosPage} />
          <Route path="/solucoes-de-integracao" component={SolucoesIntegracaoPage} />
          <Route path="/meios-de-pagamento" component={MeiosPagamentoPage} />
          <Route path="/gerenciamento-de-projetos" component={GerenciamentoProjetosPage} />
          <Route path="/parceiros-derrico" component={ParceirosGridPage} />
          <Route path="/parceiros-derrico-*" component={ParceirosDetailsPage} />
          <Route path="/blog-derrico" component={BlogGridPage} />
          <Route path="/blog-derrico-*" component={BlogDetailsPage} />
          <Route path="/contato" component={ContactPage} />
          <Route path="/politica-da-empresa" component={CompanyPolicy} />
          <Route path="/politica-de-privacidade" component={PrivacyPolicy} />
          <Route component={Error} />
        </Switch>
      </Route>
    </React.Fragment>
  );
}

export default App;
