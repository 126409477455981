import React  from 'react'
import { FiCheckCircle } from 'react-icons/fi'
import { FaCaretRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import RelatedProject from "./RelatedProject";
import sectiondata from "../../store/store";

export default function PortfolioMentoriasDetailsContent() {
    return (
        <section className="portfolio-details-wrapper">
            <div className="container">
                <div className="portfolio-details-image-box">
                    <div className="portfolio-details-image">
                        <img src={sectiondata.portfoliomentoriasdata.img} alt="Portfolio Details" />
                    </div>
                    <div className="portfolio-details-info-grid d-grid">
                        { sectiondata.portfoliomentoriasdata.equipe ?
                            <div className="item">
                                <h4>Equipe</h4>
                                <span className="d-flex">{sectiondata.portfoliomentoriasdata.equipe}</span>
                            </div>
                        : '' }
                        { sectiondata.portfoliomentoriasdata.duracao ?
                            <div className="item">
                                <h4>Duração</h4>
                                <span className="d-flex">{sectiondata.portfoliomentoriasdata.duracao}</span>
                            </div>
                        : '' }
                        { sectiondata.portfoliomentoriasdata.foco ?
                            <div className="item">
                                <h4>Foco</h4>
                                <span className="d-flex">{sectiondata.portfoliomentoriasdata.foco}</span>
                            </div>
                            : ''}
                        { sectiondata.portfoliomentoriasdata.tecnologia ?
                            <div className="item">
                                <h4>Tecnologia</h4>
                                <span className="d-flex">{sectiondata.portfoliomentoriasdata.tecnologia}</span>
                            </div>
                            : ''}
                    </div>
                    <div className="portfolio-details-content">
                        { sectiondata.portfoliomentoriasdata.title ?
                            <h1>{sectiondata.portfoliomentoriasdata.title}</h1>
                        : '' }
                        { sectiondata.portfoliomentoriasdata.content1 ?
                            <p>{sectiondata.portfoliomentoriasdata.content1}</p>
                        : '' }
                        { sectiondata.portfoliomentoriasdata.content2 ?
                            <p>{sectiondata.portfoliomentoriasdata.content2}</p>
                        : '' }
                        { sectiondata.portfoliomentoriasdata.content3 ?
                            <p>{sectiondata.portfoliomentoriasdata.content3}</p>
                        : '' }
                    </div>
                    <div className="portfolio-details-project-lists d-grid">
                        { sectiondata.portfoliomentoriasdata.projectfeature.title || sectiondata.portfoliomentoriasdata.projectfeature.lists ?
                            <div className="item project-features">
                                { sectiondata.portfoliomentoriasdata.projectfeature.title ?
                                    <h3>{sectiondata.portfoliomentoriasdata.projectfeature.title}</h3>
                                    : '' }
                                { sectiondata.portfoliomentoriasdata.projectfeature.lists ?
                                    <ul>
                                        {sectiondata.portfoliomentoriasdata.projectfeature.lists.map((item, i) => {
                                            return (
                                                <li key={i} className="d-flex"><FaCaretRight className="icon" />{item}</li>
                                            )
                                        })}
                                    </ul>
                                    : '' }
                            </div>
                        : '' }
                        { sectiondata.portfoliomentoriasdata.solutions.title || sectiondata.portfoliomentoriasdata.solutions.content || sectiondata.portfoliomentoriasdata.solutions.lists ?
                            <div className="item solution">
                                { sectiondata.portfoliomentoriasdata.solutions.title  ?
                                    <h3>{ sectiondata.portfoliomentoriasdata.solutions.title }</h3>
                                    : '' }
                                { sectiondata.portfoliomentoriasdata.solutions.content ?
                                    <p>{ sectiondata.portfoliomentoriasdata.solutions.content }</p>
                                    : '' }
                                { sectiondata.portfoliomentoriasdata.solutions.lists ?
                                    <ul>
                                        { sectiondata.portfoliomentoriasdata.solutions.lists.map((item, i) => {
                                            return (
                                                <li key={i} className="d-flex"><FiCheckCircle className="icon" />{item}</li>
                                            )
                                        })}
                                    </ul>
                                    : '' }
                            </div>
                        : '' }
                        { sectiondata.portfoliomentoriasdata.bestresult.title || sectiondata.portfoliomentoriasdata.bestresult.img ?
                            <div className="item results">
                                { sectiondata.portfoliomentoriasdata.bestresult.title ?
                                    <h3>{ sectiondata.portfoliomentoriasdata.bestresult.title }</h3>
                                    : '' }
                                { sectiondata.portfoliomentoriasdata.bestresult.img ?
                                    <img src={ sectiondata.portfoliomentoriasdata.bestresult.img } alt="chart bar" />
                                    : '' }
                            </div>
                        : '' }
                    </div>
                    {/* Related Project */}
                    <RelatedProject title={sectiondata.portfoliomentoriasdata.relatedproject.title} items={sectiondata.portfoliomentoriasdata.relatedproject.lists} />
                </div>
            </div>
        </section>
    )
}
