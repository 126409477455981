import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation to access the current path
import Navbar from '../components/common/Navbar';
import Breadcrumb from '../components/common/Breadcrumb';
import BlogDetailsContent from '../components/blog/BlogDetailsContent';
import Footer from '../components/other/Footer';

export default function BlogDetailsPage() {
    const location = useLocation();
    const lastSegment = location.pathname.split('/').pop();

    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title="Artigo" />

            {/* Blog Details */}
            <BlogDetailsContent id={lastSegment} />

            {/* Footer */}
            <Footer />
        </>
    );
}
