import React  from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import SectionsTitle from '../components/common/SectionsTitle'
import Service from '../components/services/Services'
import ServiceSliderSection from "../sections/ServiceSliderSection";
import ServiceVideo from '../components/services/ServiceVideo';
import Divider from '../components/other/Divider'
import CtaForm from '../components/other/CtaForm'
import Footer from '../components/other/Footer'
import sectiondata from "../store/store";
import ServiceSection1 from "../sections/services/ServiceSection1";

export default function GerenciamentoProjetosPage() {
    return (
        <div className="service-main">
            <header className="header-area">
                <Navbar/>
            </header>
            <Breadcrumb title="Gerenciamentos de Projetos"/>
            <div className="h-service">
                <div className="container">
                    <div className="row text-center">
                        <SectionsTitle title={sectiondata.Services.Service1.secTitle}/>
                    </div>
                </div>
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.Service1.serviceLists}/>
                    </div>
                </div>
            </div>
            <div className="h-service">
                <div className="container">
                    <div className="row text-center">
                        <SectionsTitle title={sectiondata.Services.Service4.secTitle}/>
                    </div>
                </div>
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.Service4.serviceLists}/>
                    </div>
                </div>
            </div>
            <div className="h-service">
                <div className="container">
                    <div className="row text-center">
                        <SectionsTitle title={sectiondata.Services.Service5.secTitle}/>
                    </div>
                </div>
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.Service5.serviceLists}/>
                    </div>
                </div>
            </div>
            <div className="h-service">
                <div className="container">
                    <div className="row text-center">
                        <SectionsTitle title={sectiondata.Services.Service6.secTitle}/>
                    </div>
                </div>
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.Service6.serviceLists}/>
                    </div>
                </div>
            </div>
            <div className="h-service">
                <div className="container">
                    <div className="row text-center">
                        <SectionsTitle title={sectiondata.Services.Service7.secTitle}/>
                    </div>
                </div>
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.Service7.serviceLists}/>
                    </div>
                </div>
            </div>
            <div className="h-service">
                <div className="container">
                    <div className="row text-center">
                        <SectionsTitle title={sectiondata.Services.Service8.secTitle}/>
                    </div>
                </div>
                <div className="container">
                    <div className="services-items-grid">
                        <Service sLists={sectiondata.Services.Service8.serviceLists}/>
                    </div>
                </div>
            </div>

            {/* Services Slider*/}
            {/*<ServiceSliderSection*/}
            {/*    serviceSlideritem={sectiondata.Services.NossosProdutos.lists}*/}
            {/*    sectitle={sectiondata.Services.NossosProdutos.sectitle}*/}
            {/*    secsubtitle={sectiondata.Services.NossosProdutos.secsubtitle}*/}
            {/*    content={sectiondata.Services.NossosProdutos.content}*/}
            {/*    buttontext={sectiondata.Services.NossosProdutos.buttontext}*/}
            {/*    buttonurl={sectiondata.Services.NossosProdutos.buttonurl}/>*/}

            {/* Services Video */}
            {/*<ServiceVideo/>*/}
            {/*<Divider/>*/}

            {/* CTA Form */}
            <div className="form-white-bg">
                <CtaForm/>
            </div>

            {/* Footer */}
            <Footer/>
        </div>
    )
}
